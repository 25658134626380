import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FileDisplayDialogComponent} from './file-display-dialog.component';
import {FileDisplayModule} from '../file-display.module';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {DocumentTypeDocumentListDisplayButtonModule} from '../../document-type-document-list-display/button/document-type-document-list-display-button.module';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
    declarations: [
        FileDisplayDialogComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        FileDisplayModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatDialogModule,
        DocumentTypeDocumentListDisplayButtonModule,
        MatDividerModule
    ]
})
export class FileDisplayDialogModule {}
