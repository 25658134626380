import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmStatusDialogComponent} from '../../../../shared/components/confirm-status-dialog/confirm-status-dialog.component';
import {CommentInterface, StatusEnum, StayDetailCommentService} from './stay-detail-comment.service';
import {DataSetElement} from '../../shared/data-set-element.model';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {DateHelperService} from '../../../../core/services/date.helper.service';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../../../core/authentication/authentication.service';

@Component({
  selector: 'ct-stay-detail-comment',
  templateUrl: './stay-detail-comment.component.html',
  styleUrls: ['./stay-detail-comment.component.scss']
})
export class StayDetailCommentComponent implements OnInit, OnDestroy {
  comments: CommentInterface[] = [];
  @Input() dataSetElement: DataSetElement;
  message: FormControl = new FormControl('', [Validators.required]);
  private _subscription: Subscription;

  constructor(
      private _translateService: TranslateService,
      private _dialog: MatDialog,
      private _broadcastService: BroadcastService,
      private _dateHelperService: DateHelperService,
      private _stayDetailCommentService: StayDetailCommentService,
      private _authenticationService: AuthenticationService
      ) { }

  ngOnInit(): void {
      this._subscription = this._broadcastService.broadcastData
          .subscribe(res => {
              switch (res.message) {
                  case 'dataSetElement:codification:history':
                      this.comments = res.data.comment;
                      break;
                  default:
              }
          });
  }

  getErrorMessage(): string {
      return this.message.hasError('required') ? this._translateService.instant('DATA_SET.COMMENT.VALIDATOR.REQUIRED') : '';
  }

  async sendMessage() {
      const comment: CommentInterface = await this._stayDetailCommentService.setOne(this.dataSetElement.id, {
          message: this.message.value,
          userId: this._authenticationService.user.id,
          dataSetElementId: this.dataSetElement.parent.dataSetContentId,
          status: StatusEnum.Active
      });
      this.comments.push(comment);
      this.message.reset();
  }

  deleteMessage(index: number) {
    const title = this._translateService.instant('DATA_SET.COMMENT.DIALOG.DELETE');
    const confirmDialogRef: MatDialogRef<ConfirmStatusDialogComponent> =
        this._dialog.open(ConfirmStatusDialogComponent, {
            data: {
                title
            }
        });

    confirmDialogRef
        .afterClosed()
        .subscribe(async (answer) => {
            if (answer) {
                const updatedComment = {id: this.comments[index].id, status: StatusEnum.Deactivated }; // shallow copy
                this.comments[index] = await this._stayDetailCommentService.updateOne(this.dataSetElement.id, updatedComment);
            }
        });
    }

    getDisplayDate(date: string): string {
        return this._dateHelperService.mysqlToFrench(date);
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }
}
