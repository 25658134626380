import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {StayDetailService} from '../../../modules/stay/detail/stay-detail.service';
import {ConfigurationService} from '../../../modules/configuration/configuration.service';
import {InnerHitsService} from '../../../core/services/inner-hits.service';
import {UseCases} from '../../../modules/filter-search/enums/use-cases';

@Component({
    selector: 'ct-document-highlight-preview',
    templateUrl: './document-highlight-preview.component.html',
    styleUrls: ['./document-highlight-preview.component.scss']
})
export class DocumentHighlightPreviewComponent implements OnInit {
    @Input() element: any;
    @Input() innerHits: any;
    @Input() useCase = 'stay';
    @Input() isInnerHitsGroupingAlreadyDone = false;
    @Input() forStayDetailJustification = false;

    canDisplayStayRisk: boolean;
    hasHighlightData = false;
    public readonly UseCases = UseCases;
    
    constructor(private _configurationService: ConfigurationService,
                private _innerHitsService: InnerHitsService,
                private _stayDetailService: StayDetailService) { }

    ngOnInit(): void {
        this._initConfig();
        this._groupByDocumentType();
    }

    private _initConfig(): void {
        this.canDisplayStayRisk = this._configurationService.getConfigurationContent('front', 'health.canDisplayStayRisk');
    }

    /**
     * Group results by document type
     * @private
     */
    private _groupByDocumentType(): void {
        this.hasHighlightData = this.isInnerHitsGroupingAlreadyDone;
        if (!this.isInnerHitsGroupingAlreadyDone &&
            this.innerHits) {
            this.element.highlight = [];
            if (this.innerHits.documentContent?.length > 0) {
                this.hasHighlightData = true;
                this._innerHitsService.groupDocumentContent(this.element.highlight, this.innerHits);
            }

            if (this.innerHits.structuredData?.length > 0) {
                this.hasHighlightData = true;
                this._innerHitsService.groupStructuredData(this.element.highlight, this.innerHits);
            }
        }
    }

    getDocumentCreationDate(creationDate: string): string {
        return moment(creationDate).format('DD/MM/YYYY');
    }

    getChipColor(creationDate: string): string {
        return this._stayDetailService
            .getChipColor(this.element, creationDate);
    }

    getInnerHtml(object: any): string {
        if (object &&
            object.highlight) {
            return `... ${object.highlight.content} ...`;
        }
        return '';
    }
}
