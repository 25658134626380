<mat-form-field class="full-width">
    <mat-chip-list #chipList>
        <mat-chip *ngFor="let selectedQualifactControl of selectedQualifactControls; trackBy: trackByFn"
                  [ngClass]="{'background-color-grey': selectedQualifactControl.isInferredDocumentType}"
                  [value]="selectedQualifactControl.code"
                  [matTooltip]="getTooltip(selectedQualifactControl.label)"
                  [removable]="true"
                  (removed)="removeChip(selectedQualifactControl)">
            {{ selectedQualifactControl.code }}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input #qualifactControlInput
               [placeholder]="'DATA_SET.CODIFICATION.SEARCH.QUALIFACT_CONTROL' | translate"
               [formControl]="formControl"
               [matAutocomplete]="auto"
               [matChipInputFor]="chipList">
    </mat-chip-list>

    <mat-autocomplete #auto="matAutocomplete"
                      (optionSelected)="selectQualifactControl($event)">
        <mat-option *ngFor="let searchedQualifactControl of searchedQualifactControls$ | async; index as i"
                    [value]="searchedQualifactControl">
            {{ searchedQualifactControl.code }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
