import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionQualifactSmrComponent} from './search-engine-condition-qualifactSmr.component';
import {DocumentTypeListService} from '../../../document-type/list/document-type-list.service';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionQualifactSmrService extends SearchEngineCondition {
    constructor(private _translateService: TranslateService,
                private _documentTypeListService: DocumentTypeListService) {
        super([],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.QUALIFACT_SMR'),
            'qualifactSmr',
            SearchEngineConditionQualifactSmrComponent,
            true);
        this.init();
    }

    async init(uriParams?: any, apiParams?: ApiCriteria): Promise<void> {
        this.params = {
            codes: [],
            label: '',
            revaluation: { minValue: '', operator: '>='},
        };
        await this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.params = {...this.apiParams.args, revaluation: this.apiParams.args?.revaluation ?
                this.apiParams.args?.revaluation : { minValue: '', operator: '>='}};
    }


    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {...this.params,
            revaluation: this.params.revaluation?.minValue ? this.params.revaluation : [] };
    }
}
