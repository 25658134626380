import {Component, Input, OnInit} from '@angular/core';
import {StateService} from '@uirouter/core';

@Component({
  selector: 'ct-stay-detail-element-qualifact-control',
  templateUrl: './stay-detail-element-qualifact-control.component.html',
  styleUrls: ['./stay-detail-element-qualifact-control.component.scss']
})
export class StayDetailElementQualifactControlComponent implements OnInit {

   panelOpenState = false;
    isRehabilitation = false;
   @Input() qualifactControl: any;
   @Input() isActived: boolean;

  constructor(
      public $state: StateService,
  ) { }

  ngOnInit(): void {
      this.isRehabilitation = this.$state.params.fromRehabilitationStayList === 'true';
  }

}
