<div class="flex-column-full-height">
    <mat-toolbar class="dialog-toolbar">
        <h2 class="title">
            {{ (isDocumentation ? 'STUFF.DOCUMENTATION.DOCUMENTATION' : 'STUFF.DOCUMENTS') | translate}}
        </h2>
        <button mat-icon-button
                mat-dialog-close>
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </mat-toolbar>

    <div *ngIf="!isDocumentation" class="row flex-grow-1">
        <div class="col-3 no-padding-right">
            <ct-stay-detail-document-list [documents]="documents"
                                          [maxHeight]="'90vh'"
                                          [applyPaddingTop]="true"
                                          (clickOnDocumentName)="openDocument($event)"></ct-stay-detail-document-list>
        </div>

        <mat-divider class="width-auto no-padding"
                     [vertical]="true"></mat-divider>
        <div class="col no-padding">
            <ct-file-display *ngIf="displayDocumentData.src && !displayDocumentData?.healthDocument?.structuredData?.lines?.length"
                             [data]="displayDocumentData"
                             [containerHeight]="'90vh'"
                             [objectHeight]="'100%'"></ct-file-display>
            <ct-stay-detail-document-content-structured
                *ngIf="displayDocumentData?.healthDocument?.structuredData?.lines?.length"
                [document]="displayDocumentData.healthDocument"
            ></ct-stay-detail-document-content-structured>
            <div class="align-center-center no-data full-height"
                 *ngIf="!displayDocumentData.src && !displayDocumentData?.healthDocument?.structuredData?.lines?.length">
                {{'QUALITY_CONTROL.PLEASE_SELECT_DOCUMENT' | translate}}
            </div>
        </div>
    </div>

    <div *ngIf="isDocumentation" class="row flex-grow-1">
        <div class="col-3 no-padding-right">
            <ct-stay-detail-document-list [documentations]="documentations"
                                          [maxHeight]="'90vh'"
                                          [applyPaddingTop]="true"
                                          (clickOnDocumentationName)="openDocumentation($event)"></ct-stay-detail-document-list>
        </div>

        <mat-divider class="width-auto no-padding"
                     [vertical]="true"></mat-divider>
        <div class="col no-padding">
            <ct-file-display
                *ngIf="displayDocumentationData"
                class="flex-grow-1"
                [data]="displayDocumentationData"
                [containerHeight]="'90vh'"
                [objectHeight]="'100%'"
            ></ct-file-display>
        </div>
    </div>
</div>
