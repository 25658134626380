import {Injectable} from '@angular/core';
import {UserApiService} from '../../core/api-services/user/user.api.service';
import {User} from './user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _users: User[] = [];

    constructor(private _userApiService: UserApiService) {
    }

    async loadUsers(params?: any): Promise<any> {
        try {
            let users = await this._userApiService
                .getAll(params)
                .toPromise();
            users = this.removeUsersCollective(users);
            this._users = users;
            return users;
        } catch (e) {
            throw e;
        }
    }

    async loadUser(id: number, params ?: any): Promise<any> {
        try {
            return await this._userApiService
                .get(id, params)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async deleteUser(userId: number) {
        try {
            const res = await this._userApiService
                .destroy(userId)
                .toPromise();
            this._users = this._users.filter(user => user.id !== userId);
            return res;
        } catch (e) {
            throw e;
        }
    }

    async addUser(data: any[]) {
        try {
            const res = await this._userApiService
                .create(data)
                .toPromise();
            const user = res?.user;
            if (user) {
                this._users.push(user);
            }
            return res;
        } catch (e) {
            throw e;
        }
    }

    private _updateUsersStatus(userId: number, status: boolean): void {
        const user = this._users.find(el => el.id === userId);
        if (user) {
            user.status = status;
        }
    }

    async allowUser(userId: number) {
        try {
            const userTmp: User = await this._userApiService
                .update(userId, {status: 1})
                .toPromise();
           this._updateUsersStatus(userId, userTmp?.status);
            return userTmp;
        } catch (e) {
            throw e;
        }
    }

    async disallowUser(userId: number) {
        try {
            const userTmp = await this._userApiService
                .update(userId, {status: 0})
                .toPromise();
            this._updateUsersStatus(userId, userTmp?.status);
            return userTmp;
        } catch (e) {
            throw e;
        }
    }

    async update(data: any, userId?: number) {
        return await this._userApiService
            .update(userId, data)
            .toPromise();
    }

    async updatePassword(data: any, userId?: number) {
        try {
            let authUserId;
            const authUser = JSON.parse(localStorage.getItem('user')) || {};
            if (authUser?.id) {
                authUserId = authUser.id;
            }
            if (userId && authUserId === userId) {
               return await this._userApiService
                    .updateOwnPassword(userId, data)
                    .toPromise();
            } else {
                return await this._userApiService
                    .updatePassword(userId, data)
                    .toPromise();
            }
        } catch (e) {
            throw e;
        }
    }

    getUsers(): User[] {
        return this._users?.slice() || [];
    }

    getInitials(user?: User): string {
        const userInitial = '';
        if (user) {
            const splitName =  user?.fullName ? user.fullName.split(' ') : null;
            if (splitName) {
                user.firstName = splitName[0];
                user.lastName = splitName[1];
            }
            return user.firstName.slice(0, 1).toUpperCase() + user.lastName.slice(0, 1).toUpperCase();
        }
        return userInitial;
    }

    private removeUsersCollective(users: any[]): any[] {
        const newUsers = [];
        users.forEach(
            function(user) {
                if (user.email) {
                    const userRest = user.email.substring(user.email.indexOf('@'));
                    if (userRest && userRest !== '@collective-thinking.com') {
                       newUsers.push(user);
                    }
                } else {
                    newUsers.push(user);
                }
            });
        return newUsers;
    }
}
