export const QueryTypes = {
    Bool: 'bool',
};

export const CriteriaTypes = {
    DiagnosisMissing: 'diagnosisMissing',
    StayId: 'stayId',
    DocumentContent: 'documentContent',
    QualifactControl: 'qualifactControl',
    QualifactSmr: 'qualifactSmr',
    StructuredData : 'structuredData'
};
