<ng-container *ngIf="isActive">
    <div class="row"
         [ngClass]="{'margin-bottom-10': impreciseDiagnostics.length}">
        <div class="col no-padding">
            <div *ngFor="let impreciseDiagnostic of orderDiagnostics(impreciseDiagnostics)"
                 class="custom-chip cursor-pointer background-color {{isSelected(impreciseDiagnostic) ? 'border-color' : ''}}"
                 [ngClass]="[getDiagnosticLabelColor(impreciseDiagnostic), getDiagnosticLabelColor(impreciseDiagnostic, '100')]"
                 (click)="clickOnImpreciseDiagnostic(impreciseDiagnostic)">
                <mat-icon class="diagnostic-note-icon text-color"
                          *ngIf="hasDiagnosticNotes(impreciseDiagnostic)"
                          [ngClass]="[getDiagnosticLabelColor(impreciseDiagnostic)]"
                          (click)="openDiagnosticNoteDisplayDialog(impreciseDiagnostic)">assignments</mat-icon>
                <span [ngbTooltip]="getDiagnosticTooltip(impreciseDiagnostic)"
                      tooltipClass="ngb-tooltip"
                      placement="right">{{impreciseDiagnostic.diagnostic.slug}}</span>
            </div>
        </div>
    </div>

    <!--No data-->
    <div class="row"
         *ngIf="impreciseDiagnostics.length === 0">
        <div class="col no-padding">
            <div class="text-disabled text-center">
                <span>{{'DIAGNOSTIC.NO_IMPRECISE_DIAGNOSTIC' | translate}}</span>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!isLoading && !!selectedImpreciseDiagnostic">
        <mat-divider></mat-divider>

        <div class="precise-diagnostics-block">
            <div class="row"
                 *ngFor="let preciseDiagnostic of orderDiagnostics(preciseDiagnostics); last as isLast">
                <div class="col no-padding">
                    <mat-progress-bar mode="indeterminate"
                                      *ngIf="preciseDiagnostic.diagnostic.isLoading"></mat-progress-bar>

                    <div>
                        <ct-stay-detail-diagnostic-prediction-element
                            [forceDisplay]="{value: true}"
                            [groupByParent]="{value: false}"
                            [impreciseDiagnostic]="selectedImpreciseDiagnostic"
                            [dataSetElement]="dataSetElement"
                            [diagnostics]="diagnostics"
                            [predictiveDiagnostic]="preciseDiagnostic"
                            [codificationLabels]="codificationLabels"
                            [codificationLabelIds]="[codificationLabels.DP, codificationLabels.DP_ROOT, codificationLabels.DA, codificationLabels.DA_ROOT, codificationLabels.FP, codificationLabels.MP, codificationLabels.AE]"
                            [isTypeRehabilitation]="isTypeRehabilitation"></ct-stay-detail-diagnostic-prediction-element>
                    </div>
                    <mat-divider *ngIf="!isLast"></mat-divider>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="align-center-center margin-top-20"
         *ngIf="isLoading">
        <mat-progress-spinner
            mode="indeterminate"
            [diameter]="100"></mat-progress-spinner>
    </div>
</ng-container>

<div class="align-center-center margin-top-20"
     *ngIf="!isActive">
    <mat-progress-spinner
        mode="indeterminate"
        [diameter]="100"></mat-progress-spinner>
</div>
