<div class="align-center full-height">
    <mat-radio-group
        class="margin-right-16"
        [(ngModel)]="status"
    >
        <mat-radio-button
            color="primary"
            [value]="DIAG_MISSING"
            (change)="changeStatus()"
        >
            {{ 'STAY.NOT_CODED' | translate }}
        </mat-radio-button>

        <mat-radio-button
            color="primary"
            [value]="DIAG_PRESENT"
            (change)="changeStatus()"
        >
            {{ 'STAY.CODED' | translate }}
        </mat-radio-button>
    </mat-radio-group>


    <mat-form-field *ngIf="status === DIAG_PRESENT">
        <mat-select
            multiple
            [placeholder]="'DATA_SET.CODIFICATION.SEARCH.CODIFICATION_TYPE' | translate"
            [(ngModel)]="condition.service.params.aiCodedStatus"
        >
            <mat-option
                *ngFor="let type of sources"
                [value]="type.value"
            >
                {{ type.text | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
