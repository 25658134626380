<div
    *ngIf="hasHighlightData"
    [ngClass]="{
        'margin-top-10': !forStayDetailJustification,
        'margin-bottom-separation': !forStayDetailJustification && hasHighlightData
    }"
    class="text-color"
>
    <div *ngFor="let highlight of element?.highlight">
        <ng-container *ngFor="let obj of highlight.documentContent; first as isFirst; last as isLast">
            <div class="document-type-container"
                 [ngClass]="{'padding-top-16': forStayDetailJustification, 'margin-top': (!forStayDetailJustification && isFirst && useCase !== UseCases.Patient) || useCase === UseCases.Patient}">
                <span class="document-type"><b>{{highlight.documentTypeName || highlight.documentTypeSlug}}</b></span>
            </div>

            <ng-container *ngIf="canDisplayStayRisk && obj.data">
                <div
                    *ngIf="obj.data.healthDocumentPatientCreatedAt"
                    [ngClass]="'tag-' + getChipColor(obj.data.healthDocumentPatientCreatedAt)"
                    [matTooltip]="'TOOLTIP.HIGH_DOCUMENT_PATIENT_RISK' | translate"
                    class="created-date tag active"
                >
                    {{ getDocumentCreationDate(obj.data.healthDocumentPatientCreatedAt) }}
                </div>
                <mat-icon
                    *ngIf="!obj.data.healthDocumentPatientCreatedAt"
                    class="check-icon text-color md-green"
                    [matTooltip]="'TOOLTIP.LOW_DOCUMENT_PATIENT_RISK' | translate"
                >
                    check_circle
                </mat-icon>
            </ng-container>

            <mat-icon
                *ngIf="highlight.documentTypeEssential"
                [matTooltip]="'TOOLTIP.ESSENTIAL_DOC_TYPE' | translate"
                class="vertical-align-middle margin-left-5"
            >
                stars
            </mat-icon>

            <ng-container *ngIf="useCase === UseCases.Patient && highlight.stayId !== null">
                &nbsp;(<b class="font-italic">{{'STAY.STAY' | translate}} {{'STUFF.NUMBER' | translate}} {{ highlight.stayId}}</b>)
            </ng-container>
            <div class="highlight-text"
                 *ngIf="obj.highlight && obj.highlight.content"
                 [ngClass]="{'padding-bottom-16': !isLast}"
                 [innerHTML]="getInnerHtml(obj) | safeHtml"></div>

            <mat-divider *ngIf="forStayDetailJustification && !isLast"></mat-divider>
        </ng-container>

        <div class="margin-top-20"
             *ngIf="highlight.structuredData">
            <div class="document-type-container">
                <span class="document-type"><b>{{highlight.documentTypeName || highlight.documentTypeSlug}}</b></span>
                <ng-container *ngIf="useCase === UseCases.Patient && highlight.stayId !== null">
                    &nbsp;(<b>{{'STAY.STAY' | translate}} {{'STUFF.NUMBER' | translate}} {{highlight.stayId}}</b>)
                </ng-container>
            </div>

            <ct-document-highlight-preview-structured
                [documentTypeId]="element.highlight[0]?.documentTypeId"
                [highlight]="highlight"
            ></ct-document-highlight-preview-structured>
        </div>

        <hr class="margin-right-10 margin-top-20 margin-bottom-10"/>
    </div>
</div>
