<mat-accordion [togglePosition]="'before'">
    <mat-expansion-panel [expanded]="isActived"
                        (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <b>{{ qualifactControl.label }}</b>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container-detail">
            <div>
                <p>{{ 'DATA_SET.STAY_QUALIFACT.CODE' | translate }}</p>
                <span>{{ qualifactControl.code ?? ' - ' }}</span>
            </div>
            <div>
                <p>{{ 'DATA_SET.STAY_QUALIFACT.DESCRIPTION' | translate }}</p>
                <span [innerHTML]="qualifactControl.typology?.description | safeHtml">
                </span>
                <span *ngIf="!qualifactControl.typology?.description">{{ ' - ' }}</span>
            </div>
            <ng-template *ngIf="!isRehabilitation">
                <div>
                    <p>{{ 'DATA_SET.STAY_QUALIFACT.DIAGNOSES' | translate }}</p>
                    <mat-chip *ngFor="let diag of qualifactControl.diagnoses" class="code-element">
                        {{ diag.codeDiag }}
                    </mat-chip>
                    <span *ngIf="!qualifactControl.diagnoses.length">{{ ' - ' }}</span>
                </div>
                <div>
                    <p>{{ 'DATA_SET.STAY_QUALIFACT.ACTS' | translate }}</p>
                    <mat-chip *ngFor="let act of qualifactControl.acts" class="code-element">
                        {{ act.codeActe }}
                    </mat-chip>
                    <span *ngIf="!qualifactControl.acts.length">{{ ' - ' }}</span>
                </div>
            </ng-template>
            <ng-template *ngIf="isRehabilitation">
                <div>
                    <p>{{ 'DATA_SET.STAY_QUALIFACT.REVALUATION' | translate }}</p>
                    <span *ngIf="!qualifactControl?.revaluation">{{ qualifactControl.revaluation  }}</span>
                </div>
            </ng-template>

        </div>
    </mat-expansion-panel>
</mat-accordion>

