import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {StayDetailDocumentListService} from '../../../../modules/stay/detail/document/list/stay-detail-document-list.service';
import {FileSrcService} from '../../../../core/services/file-src.service';
import * as _ from 'lodash-es';
import {StayDetailDocumentElementService} from '../../../../modules/stay/detail/document/element/stay-detail-document-element.service';
export interface IDocumentation {
    filename: string;
    src: string;
    title: string;
}
@Component({
    selector: 'ct-document-type-document-list-display-dialog',
    templateUrl: './document-type-document-list-display-dialog.component.html',
    styleUrls: ['./document-type-document-list-display-dialog.component.scss']
})
export class DocumentTypeDocumentListDisplayDialogComponent implements OnInit, OnDestroy {
    private _selectedDocument: any;
    private _inferredDocumentTypeName: string;
    private _documentTypeName: string;
    public  healthDocument: any;
    public isDocumentation: boolean;

    documents: { stayDocuments: any; patientDocuments: any };
    displayDocumentationData: IDocumentation;
    displayDocumentData: any = {src: '', filename: ''};
    documentations: IDocumentation[] = [
        {filename: 'I4H_-_Documentation_-_Manuel_administrateur.pdf', src: './assets/documentation/I4H_-_Documentation_-_Manuel_administrateur.pdf', title: 'STUFF.DOCUMENTATION.ADMINISTRATOR'},
        {filename: 'I4H_-_Documentation_-_Manuel_administrateur.pdf', src: './assets/documentation/I4H_-_Documentation_-_Manuel_utilisateur_V0.pdf', title: 'STUFF.DOCUMENTATION.USER'},
        {filename: 'I4H_-_Documentation_-_Moteur_de_Règles.pdf', src: './assets/documentation/I4H_-_Documentation_-_Moteur_de_Règles.pdf', title: 'STUFF.DOCUMENTATION.FILTER_SEARCH'}
    ];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private _stayDetailDocumentListService: StayDetailDocumentListService,
                private _fileSrcService: FileSrcService,
                private _stayDetailDocumentElementService: StayDetailDocumentElementService) {
    }

    ngOnInit(): void {
        this.documents = this.data?.documents;
        this._inferredDocumentTypeName = this.data?.inferredDocumentTypeName;
        this._documentTypeName = this.data?.documentTypeName;
        this.isDocumentation = this.data?.isDocumentation || false;
        if (!this.isDocumentation) {
            this._openDocumentOnInit();
        }
    }

    private _getDocumentToOpen(key: string): any {
        if (this.documents?.[key]?.[this._inferredDocumentTypeName] && !this.isDocumentation) {
            return this.documents[key][this._inferredDocumentTypeName]
                .find(el => el.documentType && el.documentType.name === this._documentTypeName);
        } else if (this.isDocumentation) {
            return this.documentations.find(el => el.filename === this._documentTypeName);
        }
        return null;
    }

    private _openDocumentOnInit(): void {
        const documentToOpen = this._getDocumentToOpen('stayDocuments') || this._getDocumentToOpen('patientDocuments');
        if (documentToOpen) {
            if (documentToOpen?.structuredDataLines?.length > 0) {
                this.healthDocument = _.cloneDeep(documentToOpen.structuredDataLines);
            }
            documentToOpen.isOpened = true;
            this.openDocument(documentToOpen);
        }
    }

    private _getFileSrc(document: any): string {
        return this._fileSrcService.getDocumentSrc(document);
    }

     private async _setDisplayDocumentData(document: any): Promise<void> {
        this.healthDocument = null;
        if (document) {
            if (document?.structuredDataLines?.length > 0) {
                document.documentType.structuredData = true;
                this.healthDocument = await this._stayDetailDocumentElementService
                    .loadHealthDocumentLines(document, null);
                await this._stayDetailDocumentElementService._getStructuredDataColumns(this.healthDocument)
                    .then(columns => {
                        this.healthDocument.columns = columns || [];
                    });
            }
            this.displayDocumentData = {
                src: this._getFileSrc(document),
                filename: document.filename,
                healthDocument: this.healthDocument
            };
        }
    }
    openDocumentation($event: any) {
        this.displayDocumentationData = $event;
    }

    openDocument(document: any): void {
        if (document) {
            this._selectedDocument = document;
            this._setDisplayDocumentData(document);
        }
    }

    private _setIsOpenedToFalse(): void {
        this._stayDetailDocumentListService.setIsOpenedProperty(this.documents, false);
    }

    ngOnDestroy(): void {
        this._setIsOpenedToFalse();
    }

}
