import {Injectable} from '@angular/core';
import {AuthenticationApiService} from '../api-services/authentication/authentication.api.service';
import {AuthenticationUserApiService} from '../api-services/authentication/authentication-user.api.service';
import {AuthenticationRoleApiService} from '../api-services/authentication/authentication-role.api.service';
import {Role} from '../../modules/role/role.model';
import {ForgotPasswordApiService} from '../api-services/forgot-password.api.service';
import {ConfigurationService} from '../../modules/configuration/configuration.service';
import {environment} from '../../../environments/environment';
import {StateService} from '@uirouter/core';
import {TranslationHelperService} from '../services/translation.helper.service';
import {AlertService} from '../../modules/alert/alert.service';
import * as _ from 'lodash-es';
import {User} from '../../modules/user/user.model';
import {boot, Intercom, shutdown} from '@intercom/messenger-js-sdk';

interface AuthenticatedUser {
    id: number;
    external_id?: string;
    email: string;
    firstName: string;
    lastName: string;
    picture: string;
    token: string;
    roles: any[];
    isAuthenticated: boolean;
    isLdap: boolean;
    allEstablishment?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    constructor(
        public $state: StateService,
        private _forgotPasswordApiService: ForgotPasswordApiService,
        private _authenticationApiService: AuthenticationApiService,
        private _authenticationUserApiService: AuthenticationUserApiService,
        private _authenticationRoleApiService: AuthenticationRoleApiService,
        private _alertService: AlertService,
        private _configurationService: ConfigurationService,
        private _translationHelperService: TranslationHelperService
    ) {
    }

    private _user: AuthenticatedUser;
    private _intercomEnabled = false;

    /**
     * @description
     * A prioritized list of routes for landing pages, ordered by importance.
     * Used to select the highest priority route for users with multiple roles.
     *
     * @type {string[]}
     */


    private priorities = [
        'dashboard',
        'stay-dependency',
        'quality-control',
        'patient-list',
        'filter-search-patient-list',
        'user-list',
        'document-type-list',
        'default',
    ];

    get user(): AuthenticatedUser {
        return this._user;
    }

    currentUserHasPermission(permissionName: string) {
        let hasPermission = false;
        let role: Role;

        for (let i = 0; i < this._user.roles.length; i++) {
            role = this._user.roles[i];
            if (this._roleHasPermission(role, permissionName)) {
                hasPermission = true;
                break;
            }
        }
        return hasPermission;
    }

    hasRole(roleName: string) {
        return !!(this._user.roles && this._user.roles
            .find(role => role.name === roleName));
    }

    async checkLocalStorage() {
        try {
            await this._translationHelperService.initTranslateLanguage();

            // We call isAuthenticated in every case in case we loose the back session
            // but not the user in localStorage
            const isAuthenticated = await this.isAuthenticated();

            // Clear LocalStorage and SessionStorage if user is not
            // authenticated.
            if (!isAuthenticated) {
                this.initGuestSession();

                return;
            }

            // Users that didn't log since migration don't have a user in localStorage
            const user = JSON.parse(localStorage.getItem('user')) || null;

            await this.initAuthSession(user);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private initGuestSession() {
        localStorage.clear();
        sessionStorage.clear();

        this.storeStateRedirection();
        this._init();
    }

    async isAuthenticated() {
        try {
            const authentication = await this._authenticationApiService
                .isAuthenticated()
                .toPromise();

            return Boolean(authentication?.status);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    hasNoRole() {
        return !this._user || !this._user.roles || (this._user.roles && !this._user.roles.length);
    }

    async login(user: User) {
        try {
            const userData: User = await this._authenticationApiService
                .login({
                    email: user.email,
                    password: user.password
                })
                .toPromise();

            this._user.isAuthenticated = true;

            await this._storeConfigurations();
            await this._loadSubscribedAlerts();
            await this._setUser(userData);

            this.initIntercom();

            this._handleRedirectionAfterLogin();
        } catch (e) {
            throw e;
        }
    }

    private initIntercom() {
        if (!this._intercomEnabled) {
            return;
        }

        const intercomAppId = JSON.parse(localStorage.getItem('configurations')).find((item: { name: string; }) => item.name === 'INTERCOM.appId')?.content;

        // Messenger attributes documentation
        // https://developers.intercom.com/installing-intercom/web/attributes-objects#messenger-attributes
        Intercom({
            app_id: intercomAppId,
            api_base: 'https://api-iam.intercom.io',
            background_color: '#293242', // Tailwind's 'secondary-default'.
            custom_launcher_selector: '#intercom-launcher',
            hide_default_launcher: true,
        });

        // Data attributes documentation
        // https://developers.intercom.com/installing-intercom/web/attributes-objects#data-attributes
        boot({
            app_id: intercomAppId,
            user_id: this.user.external_id,
            email: this.user.email,
            name: this.user.firstName + ' ' + this.user.lastName,
            company: {
                company_id: '1',
                name: 'i4h',
            },
        });
    }

    async logout() {
        try {
            await this._authenticationApiService.logout().toPromise();

            if (this._intercomEnabled) {
                shutdown();
            }

            localStorage.clear();
            sessionStorage.clear();

            this._init();
            this._goToLogin();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async forgotPassword(email: string) {
        try {
            return await this._forgotPasswordApiService
                .call({
                    email,
                    type: 2
                }).toPromise();
        } catch (e) {
            throw e;
        }
    }

    storeStateRedirection() {
        const stateName = this.$state.current.name;
        if (stateName &&
            stateName !== 'authentication.login') {
            if (stateName === 'alert-unsubscribe') {
                sessionStorage.setItem('unsubscribeFromAlertId', this.$state.params.alertId);
            } else {
                const stateParams = _.cloneDeep(this.$state.params);
                const objectToStore = {stateName, stateParams};
                sessionStorage.setItem('stateRedirection', JSON.stringify(objectToStore));
            }
        }
    }

    redirectAccordingToRole() {
        if (!this._handleCurrentUserHasNoRole()) {
            const {redirection, params} = this._getRedirectionAccordingToRole();
            this.$state.transitionTo(redirection, params);
        }
    }

    private _setUserData(data: User) {
        this._user.id = data.id;
        this._user.external_id = data.external_id;
        this._user.email = data.email;
        this._user.firstName = data.firstName;
        this._user.lastName = data.lastName;
        this._user.isLdap = data.isLdap;
        this._user.allEstablishment = data.allEstablishment;
    }

    private async _setUser(userData?: User) {
        try {
            let data = userData;
            if (!userData) {
                data = await this._authenticationUserApiService
                    .get(null)
                    .toPromise();
            }
            this._setUserData(data);
            await this._setUserRoles();
        } catch (e) {
            throw e;
        }
    }

    private _goToLogin() {
        this.$state.go('authentication.login');
    }

    private _roleHasPermission(role: any, permissionName: string) {
        if (role && role.permissions && role.permissions.length) {
            const permissionFound = role.permissions.find(permission =>
                permission.name === permissionName
            );
            return !!permissionFound;
        }
        return false;
    }

    private _updateStateTranslations(): void {
        const appTypeIsMCOAndSSR = this._configurationService.appTypeIsMCOAndSSR();
        if (appTypeIsMCOAndSSR) {
            this._translationHelperService.STATES_TRANSLATIONS['stay-list'] = 'TOOLBAR.STAY_LIST_MCO';
            this._translationHelperService.STATES_TRANSLATIONS['stay-list-rehabilitation'] = 'TOOLBAR.STAY_LIST_REHABILITATION';
        }
    }

    private async _storeConfigurations() {
        try {
            const configurations = await this._configurationService.loadAllConfigurations();

            if (configurations?.length) {
                localStorage.setItem('configurations', JSON.stringify(configurations));

                // Update translateService translations for customWording
                this._configurationService.updateConfigurationFromLocalStorage();
                this._updateStateTranslations();
            } else {
                this._storeDefaultConfiguration();
            }
        } catch (e) {
            this._storeDefaultConfiguration();
            throw e;
        } finally {
            this._intercomEnabled = this._configurationService.getConfigurationContent('front', 'INTERCOM.enabled');
        }
    }

    /**
     * In case configuration request fails or empty
     * @private
     */
    private _storeDefaultConfiguration() {
        const defaultConfigurations = environment.configurations;
        localStorage.setItem('configurations', JSON.stringify(defaultConfigurations));
    }

    private async _loadSubscribedAlerts() {
        try {
            await this._alertService.loadSubscribedAlerts({include: 'users'});
        } catch (e) {
            console.error(e);
            if (e &&
                e.status !== 403) {
                throw e;
            }
        }
    }

    private _init() {
        this._user = {
            id: 0,
            external_id: '',
            email: '',
            firstName: '',
            lastName: '',
            picture: './assets/images/default-user-icon-profile.png',
            token: '',
            roles: [],
            isAuthenticated: false,
            isLdap: false
        };
    }

    private _handleRedirectionAfterLogin() {
        const stateRedirection = sessionStorage.getItem('stateRedirection');
        const alertId = sessionStorage.getItem('unsubscribeFromAlertId');
        if (stateRedirection) {
            const state: any = JSON.parse(stateRedirection);
            this.$state.go(state.stateName, state.stateParams);
        } else if (alertId) {
            this.$state.go('alert-unsubscribe', {alertId});
        } else {
            this.redirectAccordingToRole();
        }
    }

    private _handleCurrentUserHasNoRole() {
        if (this.hasNoRole()) {
            this.$state.go('authentication.need-access-rights');
            return true;
        }
        return false;
    }

    private async _setUserRoles() {
        try {
            const roles: Role[] = await this._authenticationRoleApiService
                .getAllUserRoles(this._user.id, {include: 'permissions'})
                .toPromise();

            if (roles) {
                this._user.roles = roles;
            }

            localStorage.setItem('user', JSON.stringify(this._user));
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private _getRedirectionAccordingToRole(): any {
        const params: any = {
            dataSetId: 1,
            codificationId: 1
        };
        const redirection = this.getHighestPriorityLandingPage(this.user.roles);
        return {redirection, params};
    }


    private getHighestPriorityLandingPage(pages: any): string | null {
        let highestPriorityPage = null;
        let highestPriorityIndex = this.priorities.length;

        for (const page of pages) {
            const currentIndex = this.priorities.indexOf(page.landingPage);
            if (currentIndex !== -1 && currentIndex < highestPriorityIndex) {
                highestPriorityPage = page.landingPage;
                highestPriorityIndex = currentIndex;
            }
        }
        if (highestPriorityPage === 'default') {
            return this.getDefaultLandingPage();
        }
        return highestPriorityPage;
    }

    private getDefaultLandingPage(): string {
        if (this._configurationService.appTypeIs('MCO') || this._configurationService.appTypeIsMCOAndSSR()) {
            return 'stay-list';
        }
        if (this._configurationService.appTypeIs('SSR')) {
            return 'stay-list-rehabilitation';
        }
        return 'default';
    }
    private async initAuthSession(user: AuthenticatedUser | null) {
        if (user?.isAuthenticated && user?.email !== '') {
            this._user = user;

            await this._setUserRoles();
            await this._storeConfigurations();
            await this._loadSubscribedAlerts();

            this.initIntercom();

            this._handleCurrentUserHasNoRole();

            return;
        }

        this._init();

        this._user.isAuthenticated = true;

        await this._setUser();
        await this._storeConfigurations();
        await this._loadSubscribedAlerts();

        this.redirectAccordingToRole();
    }
}
