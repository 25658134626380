import {Injectable} from '@angular/core';
import {DocumentTypeApiService} from '../../../core/api-services/document-type/document-type.api.service';
import {DocumentType} from '../document-type.model';

@Injectable({
    providedIn: 'root'
})
export class DocumentTypeListService {

    constructor(private _documentTypeApiService: DocumentTypeApiService) { }

    async loadAllDocumentTypes(params?: any): Promise<any> {
        try {
            params = params || {};

            return await this._documentTypeApiService
                .getAll(params)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async updateDocumentType(documentTypeId: number, data: any): Promise<DocumentType> {
        try {
            return await this._documentTypeApiService
                .update(documentTypeId, data)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    loadColumns$(documentTypeId: number) {
        return this._documentTypeApiService
            .getStructuredDataColumn(documentTypeId);
    }

    async recalculateDocumentTypeData(documentTypeIds: number[]|null = null) {
        return await this._documentTypeApiService.recalculateDocumentTypeData({documentTypeIds}).toPromise();
    }

    async getDocumentsCountTimestamps() {
        return await this._documentTypeApiService.getDocumentsCountTimestamps().toPromise();
    }
}
