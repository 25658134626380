import {AfterContentInit, Component, OnInit} from '@angular/core';
import {AuthenticationService} from './core/authentication/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {VersionCheckService} from './core/services/version-check.service';
import {environment} from '../environments/environment';
import {DateAdapter} from '@angular/material/core';
import * as moment from 'moment';
import {NgbTooltipConfig} from '@ng-bootstrap/ng-bootstrap';
import {TransitionService} from '@uirouter/core';
import {update} from '@intercom/messenger-js-sdk';
import {ConfigurationService} from './modules/configuration/configuration.service';

@Component({
    selector: 'ct-app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentInit {
    isActive: boolean;

    constructor(
        private _translateService: TranslateService,
        private _dateAdapter: DateAdapter<any>,
        private _versionCheckService: VersionCheckService,
        private _authenticationService: AuthenticationService,
        private _ngbTooltipConfig: NgbTooltipConfig,
        private _transitionService: TransitionService,
        private _auth: AuthenticationService,
        private _config: ConfigurationService,
    ) {
        this._ngbTooltipConfig.container = 'body';
    }

    ngOnInit() {
        if (environment.production) {
            this._versionCheckService.checkBuildVersion();
        }

        this._init();
    }

    ngAfterContentInit() {
        if (this._config.getConfigurationContent('front', 'INTERCOM.enabled')) {
            this.initIntercomPaginationEvent();
        }
    }

    private initIntercomPaginationEvent() {
        this._transitionService.onSuccess({}, (transition) => {
            if (!this._auth.user?.isAuthenticated) {
                return;
            }

            update({
                user_id: this._auth.user?.external_id,
                email: this._auth.user?.email,
                name: this._auth.user?.firstName + ' ' + this._auth.user?.lastName,
            });
        });
    }

    private async _init() {
        try {
            await this._authenticationService.checkLocalStorage();
            const baseLanguage = this._translateService.currentLang.slice(0, 2);
            // For languages like fr_ch
            if (baseLanguage === 'fr') {
                this._dateAdapter.setLocale('fr');
                moment.locale('fr');
            } else {
                // Here and not in TranslationHelperService because import issue in its test file
                this._dateAdapter.setLocale(this._translateService.currentLang);
                moment.locale(this._translateService.currentLang);
            }
            this.isActive = true;
        } catch (e) {
            this._dateAdapter.setLocale('fr');
            moment.locale('fr');
            console.error(e);
            throw e;
        }
    }
}
