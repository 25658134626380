<div class="row">
    <div class="col no-padding">
        <ct-qualifact-control-search
                *ngIf="!isLoading && qualifactControls"
                [(param)]="condition.service.params.codes"
                [qualifactControls]="qualifactControls">
        </ct-qualifact-control-search>
    </div>
</div>
<div class="row">
    <div class="col no-padding">
        <mat-form-field>
            <input matInput
                   type="text"
                   [(ngModel)]="condition.service.params.revaluation.minValue"
                   [placeholder]=" 'STAY.REVALUATION' | translate">
        </mat-form-field>
    </div>
</div>

