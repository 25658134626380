<mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoading"
    [color]="colorService.getColorAccordingToCurrentState()"
></mat-progress-bar>

<div class="edit-dialog-content">
    <h1 *ngIf="!filterSearchData.id" mat-dialog-title>
        {{
        (useCase === UseCases.Patient
                ? 'FILTER_SEARCH.EDIT_DIALOG.SAVE_FILTER_PATIENT'
                : 'FILTER_SEARCH.EDIT_DIALOG.SAVE_FILTER')
            | translate
        }}
    </h1>

    <form>
        <mat-dialog-content>
            <!--Filter search sharing status-->
            <div class="row">
                <div *ngIf="!filterSearchData.id" class="col-6 pl-0">
                    <mat-form-field class="full-width">
                        <mat-select
                            [(ngModel)]="saveType"
                            [placeholder]="'FILTER_SEARCH.EDIT_DIALOG.ACTION' | translate"
                            name="saveType"
                            required
                        >
                            <mat-option value="creation">
                                {{
                                    (useCase === UseCases.Patient
                                        ? 'FILTER_SEARCH.EDIT_DIALOG.CREATE_PATIENT'
                                        : 'FILTER_SEARCH.EDIT_DIALOG.CREATE')
                                    | translate
                                }}
                            </mat-option>
                            <mat-option value="update">
                                {{
                                    (useCase === UseCases.Patient
                                        ? 'FILTER_SEARCH.EDIT_DIALOG.UPDATE_PATIENT'
                                        : 'FILTER_SEARCH.EDIT_DIALOG.UPDATE')
                                    | translate
                                }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-6 pr-0">
                    <mat-form-field class="full-width">
                        <mat-select
                            [(ngModel)]="filterSearchData.sharedStatus"
                            [placeholder]="'FILTER_SEARCH.SCOPE' | translate"
                            (selectionChange)="setCodifyValue()"
                            name="sharedStatus"
                            required
                        >
                            <mat-option value="isPublic">{{ 'FILTER_SEARCH.PUBLIC' | translate }}</mat-option>
                            <mat-option value="isPrivate">{{ 'FILTER_SEARCH.PRIVATE' | translate }}</mat-option>
                            <mat-option value="isCommon">{{ 'FILTER_SEARCH.COMMON' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="margin-top-10">
                <ng-container *ngIf="!filterSearchData.id && saveType === 'update'">
                    <div class="row">
                        <div class="col">
                            <mat-form-field
                                class="full-width"
                                [color]="colorService.getColorAccordingToCurrentState()"
                            >
                                <mat-select
                                    [(ngModel)]="filterSearchToUpdateId"
                                    [panelClass]="['filter-search-edit-select', 'mat-' + colorService.getColorAccordingToCurrentState()]"
                                    [placeholder]="(useCase === UseCases.Patient
                                            ? 'FILTER_SEARCH.EDIT_DIALOG.FILTERS_PATIENT_TO_EDIT'
                                            : 'FILTER_SEARCH.EDIT_DIALOG.FILTERS_TO_EDIT')
                                        | translate"
                                    name="filterSearch"
                                    required
                                    (selectionChange)="selectFilterSearchToUpdate()"
                                >
                                    <mat-option>
                                        <ngx-mat-select-search
                                            [formControl]="filterSearchCtrl"
                                            [placeholderLabel]="'BUTTON.SEARCH' | translate"
                                        ></ngx-mat-select-search>
                                    </mat-option>

                                    <mat-option *ngIf="filtersSearch && filtersSearch.length === 0">
                                        {{ 'FILTER_SEARCH.EDIT_DIALOG.NO_RESULT' | translate }}
                                    </mat-option>

                                    <mat-option
                                        *ngFor="let filterSearch of filtersSearch"
                                        [value]="filterSearch.id"
                                    >
                                        {{ filterSearch.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="filterSearchData.id || saveType === 'creation'">
                    <div class="row">
                        <div class="col">
                            <mat-form-field
                                [color]="colorService.getColorAccordingToCurrentState()"
                                class="full-width"
                            >
                                <input matInput
                                       required
                                       type="text"
                                       name="name"
                                       [(ngModel)]="filterSearchData.name"
                                       [placeholder]="('FILTER_SEARCH.EDIT_DIALOG.NAME' | translate)
                                           + ' '
                                           + ((useCase === UseCases.Patient ? 'FILTER_SEARCH.EDIT_DIALOG.COHORTE' : 'FILTER_SEARCH.EDIT_DIALOG.RULE') | translate)"
                                >
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="margin-top-20">
                <ng-container>
                    <div class="row">
                        <div class="col">
                            <label class="label margin-right-5 display-inline-block margin-bottom-5">
                                {{ 'FILTER_SEARCH.SAVE' | translate }}:
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <!--Worklist checkbox in save rules modal-->
                            <mat-checkbox
                                [(ngModel)]="isWorkList"
                                class="padding-5 margin-right-10"
                                name="workList"
                                color="primary"
                                aria-describedby="rule-type"
                            >
                                {{ 'FILTER_SEARCH.LIST.WORKLIST' | translate }}
                            </mat-checkbox>

                            <span *ngIf="authenticationService.hasRole('admin')" class="margin-top-10 padding-5 margin-right-5 rule-type-checkbox-container">
                                <mat-checkbox
                                    [(ngModel)]="filterSearchData.autoSupervised"
                                    [disabled]="filterSearchData.automatable"
                                    [ngClass]="{'bg-secondary bg-opacity-10 rounded': filterSearchData.autoSupervised}"
                                    class="padding-5"
                                    name="autoSupervised"
                                    color="primary"
                                    aria-describedby="rule-type"
                                >
                                    {{ 'FILTER_SEARCH.AUTO_SUPERVISED' | translate }}
                                </mat-checkbox>
                            </span>

                            <span
                                *ngIf="useCase !== UseCases.Patient && authenticationService.hasRole('admin')"
                                class="margin-top-10 padding-5 rule-type-checkbox-container"
                            >
                                <mat-checkbox
                                    [(ngModel)]="filterSearchData.automatable"
                                    [disabled]="filterSearchData.autoSupervised"
                                    [ngClass]="{'bg-secondary bg-opacity-10 rounded': filterSearchData.automatable}"
                                    class="padding-5"
                                    name="automatable"
                                    color="primary"
                                    aria-describedby="rule-type"
                                    (change)="handleDiagnoses()"
                                >
                                    {{ 'FILTER_SEARCH.AUTOMATABLE' | translate }}
                                </mat-checkbox>
                            </span>

                            <span
                                *ngIf="useCase !== UseCases.Patient && authenticationService.hasRole('admin')"
                                class="margin-top-10 padding-5 rule-type-checkbox-container"
                            >
                                <mat-checkbox
                                    [(ngModel)]="filterSearchData.isAtypia"
                                    class="padding-5"
                                    name="isAtypia"
                                    color="primary"
                                    aria-describedby="rule-type"
                                >
                                    {{ 'FILTER_SEARCH.LIST.ATYPIA' | translate }}
                                </mat-checkbox>
                            </span>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="(filterSearchData.autoSupervised || filterSearchData.automatable) && filterSearchData?.diagnoses?.length">
                    <div class="row">
                        <div class="col">
                            <div
                                class="row margin-top-10 bg-secondary bg-opacity-10 border padding-10 padding-top-15 margin-left--5 rounded"
                            >
                                <div class="col-3"
                                     *ngFor="let diagnostic of filterSearchData.diagnoses; let index = index">
                                    <b class="margin-bottom-5 display-block">{{ diagnostic.slug }} :</b>
                                    <mat-form-field class="full-width" color="primary">
                                        <mat-select
                                            placeholder="Type de diagnostic"
                                            [(ngModel)]="diagnostic.codificationLabel" [name]="'diag-' + index"
                                        >
                                            <mat-option
                                                *ngFor="let diag of ['DP', 'DR', 'DA', 'MP', 'AE']"
                                                [value]="diag"
                                            >
                                                {{ diag }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div *ngIf="setValidityFrame">
                    <ct-filter-search-edit-validity-frame
                        [useCase]="'add'"
                        (formValuesChange)="onDateFormChange($event)"
                        (enterKeyPress)="onEnterKeyPressed()"
                    ></ct-filter-search-edit-validity-frame>
                </div>

                <div class="row">
                    <div class="col">
                        <p
                            *ngIf="canDisplayWarningPrivateFilterSearch()"
                            [innerHTML]="'FILTER_SEARCH.LIST.DISPLAY.PRIVATE_FILTER_SEARCH_WARNING' | translate | safeHtml"
                            class="alert alert-info margin-top-10"
                        ></p>
                    </div>
                </div>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions>
            <button
                mat-button
                mat-dialog-close
                type="button"
                [ngClass]="[colorService.getColorAccordingToCurrentState(true)]">{{ 'BUTTON.CANCEL' | translate | uppercase }}
            </button>

            <!--Edition button-->
            <button
                *ngIf="filterSearchData.id"
                mat-raised-button
                type="submit"
                [color]="colorService.getColorAccordingToCurrentState()"
                [disabled]="isLoading || !filterSearchData.name"
                (click)="update(true)">{{ 'BUTTON.VALIDATE' | translate | uppercase }}
            </button>

            <!--Creation button-->
            <button
                *ngIf="!filterSearchData.id"
                [color]="colorService.getColorAccordingToCurrentState()"
                [disabled]="isLoading || isValidationDisabled() || (filterSearchData.automatable && filterSearchData.autoSupervised)"
                mat-raised-button
                type="submit"
                (click)="validate()"
                #submitButton
            >
                {{ 'BUTTON.VALIDATE' | translate | uppercase }}
            </button>
        </mat-dialog-actions>
    </form>
</div>
