import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionCodificationTypeComponent} from './search-engine-condition-codification-type.component';
import {Injectable} from '@angular/core';
import {TranslationHelperService} from '../../../../core/services/translation.helper.service';
import {CodificationLabelEnum} from '../../../stay/shared/codification-label-enum';
import {QueryOperators} from '../../advanced/query-builder/enums/operators';

export const DIAG_MISSING = 'diagnostic_missing';
export const DIAG_PRESENT = 'diagnostic_present';

export const DIAG_SOURCE_IMPORTED = 'imported';
export const DIAG_SOURCE_MANUAL = 'manual';
export const DIAG_SOURCE_AUTO = 'auto';

/**
 * Represents the list of codification types considered as "coded" statuses.
 */
export const ALL_DIAG_SOURCES = [
    DIAG_SOURCE_IMPORTED,
    DIAG_SOURCE_MANUAL,
    DIAG_SOURCE_AUTO,
];

@Injectable()
export class SearchEngineConditionCodificationTypeService extends SearchEngineCondition {
    constructor(
        private _translateService: TranslateService,
        private _translationHelperService: TranslationHelperService
    ) {
        super([],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.CODIFICATION_TYPE'),
            'aiCodedStatus',
            SearchEngineConditionCodificationTypeComponent,
            true,
        );
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {aiCodedStatus: ALL_DIAG_SOURCES};
        this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.params.aiCodedStatus = this.apiParams.args.values;
    }

    private _fillValues(): void {
        const DP = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DP);

        if (this.params.aiCodedStatus.includes(DIAG_MISSING)) {
            this.apiParams.args.values.push({
                type: 'codificationLabelPresence',
                args: {values: [DP], operator: QueryOperators.Nor},
            });

            return;
        }

        const sources = [];

        if (this.params.aiCodedStatus.includes(DIAG_SOURCE_IMPORTED)) {
            sources.push('imported');
        }

        if (this.params.aiCodedStatus.includes(DIAG_SOURCE_MANUAL)) {
            sources.push('manual');
        }

        if (this.params.aiCodedStatus.includes(DIAG_SOURCE_AUTO)) {
            sources.push('auto');
        }

        this.apiParams.args.values.push({
            type: 'diagnosisPresent',
            args: {
                slugs: ['*'],
                codificationLabel: [DP],
                source: sources,
                operator: QueryOperators.Or,
            },
        });
    }

    convertToApiParams(): void {
        super.convertToApiParams();

        this.apiParams.args = {values: []};

        this._fillValues();
    }

    isValidApiParams(args: any): boolean {
        return !!args?.values?.length;
    }
}
