import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'ct-stay-detail-qualifact-control',
  templateUrl: './stay-detail-qualifact-control.component.html',
  styleUrls: ['./stay-detail-qualifact-control.component.scss']
})
export class StayDetailQualifactControlComponent implements OnInit, OnDestroy {

  @Input() qualifactControls: any[];
  private _subscriptions: Subscription[] = [];
  targetControls: number[];
  constructor(private _broadcastService: BroadcastService, ) { }

    ngOnInit(): void {
      this._subscribeToBroadcast();
    }

    private _subscribeToBroadcast() {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'qualifactControl::highlight':
                        const qualifactControls = res.data.qualifactControls ?? [];
                        if (qualifactControls?.length) {
                            this.setIndexToHighlight(qualifactControls);
                        } else {
                            this.setIndexToHighlight([]);
                        }
                        break;
                    default:
                }
            });
        this._subscriptions.push(sub);
    }

    setIndexToHighlight(targetQualifactControl: any) {
        this.targetControls = [];
        this.qualifactControls.forEach((qc, index) => {
            const qcDiagnosis = JSON.stringify(qc.diagnoses?.map(diag => diag.codeDiag));
            const qcActs = JSON.stringify(qc.acts?.map(act => act.codeActe));
            const qualifactControlExisted = targetQualifactControl.find(qcFilter => {
                const diagnosesString = qcFilter?.diagnoses?.length ? JSON.stringify(qcFilter.diagnoses) : null;
                const actsString = qcFilter?.acts?.length ? JSON.stringify(qcFilter.acts) : null;

                return (!qcFilter.code || qcFilter.code === qc.code) &&
                    (!diagnosesString || qcDiagnosis === diagnosesString) &&
                    (!actsString || qcActs === actsString);
            });
            if (qualifactControlExisted) {
                this.targetControls.push(index);
            }
        });
    }

    ngOnDestroy() {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }

}
