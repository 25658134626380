import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {DateHelperService} from '../../../../core/services/date.helper.service';
import {SearchEngineConditionDateComparisonComponent} from '../../shared/search-engine-condition-date-comparison/search-engine-condition-date-comparison.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionStartDateService extends SearchEngineCondition {
    private _useCase: string;

    get useCase(): string {
        return this._useCase;
    }

    set useCase(value: string) {
        this._useCase = value;
    }

    constructor(private _translateService: TranslateService) {
        super(['startDate', 'customStartDateSubtractAmount', 'customStartDateSubtractUnit'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.START_DATE'),
            'startDate',
            SearchEngineConditionDateComparisonComponent,
            true);
        this._useCase = 'codification';
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {
            startDate: null,
            endDate: null,
            customSubtractAmount: null,
            customSubtractUnit: 'day'
        };
        this.uriParams = {
            startDate: '',
            customStartDateSubtractAmount: '',
            customStartDateSubtractUnit: 'day'
        };
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    private _updateCustomUriParams(): void {
        this.uriParams.customStartDateSubtractAmount = this.params.customSubtractAmount ? this.params.customSubtractAmount.toString() : '';
        this.uriParams.customStartDateSubtractUnit = this.params.customSubtractUnit;
    }

    private _updateCustomParams(): void {
        this.params.customSubtractAmount = Number.parseFloat(this.uriParams.customStartDateSubtractAmount);
        this.params.customSubtractUnit = this.uriParams.customStartDateSubtractUnit;
    }

    convertToUriParams(): void {
        this.updateUriParamsComparisonDate(this.uriParams, 'startDate', this.params);
        this._updateCustomUriParams();
    }

    convertToParams(): void {
        if (this.uriParams.startDate) {
            this.updateParamsComparisonDate(this.uriParams, 'startDate', this.params);
            this._updateCustomParams();
        }
    }

    convertToParamsFromApiParams(): void {
        this.updateParamsFromApiParamsComparisonDate(this.apiParams, this.params);
        this._updateCustomUriParams();
    }

    convertToApiParams(): void {
        super.convertToApiParams();

        const startDate: string = DateHelperService.toMysql(this.params.startDate);
        const endDate: string = DateHelperService.toMysql(this.params.endDate);

        this.apiParams.args = {...this.getComparisonArgsObject(startDate, endDate)};
    }

    isValid(fields?: any): boolean {
        if (!this.isAdvanced) {
            return true;
        }

        return !this.isUndefined(fields['startDate']) &&
            !this.isEmpty(fields['startDate']);
    }

    isValidApiParams(args: any): boolean {
        return !!(args && !this.equalsFalse(args.minValue) && args.operator);
    }
}
