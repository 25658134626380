import {Component, Input} from '@angular/core';
import {ColorService} from '../../../../core/services/color.service';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {SearchEngineCondition} from '../search-engine.condition';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'ct-search-engine-condition-codify',
    templateUrl: './search-engine-condition-codify.component.html',
    styleUrls: ['./search-engine-condition-codify.component.scss']
})
export class SearchEngineConditionCodifyComponent extends SearchEngineCondition {
    @Input() condition: any;
    @Input() title: string;

    constructor(public colorService: ColorService,
                private _broadcastService: BroadcastService,
                private _translateService: TranslateService) {
        super(['tagName'],
            _translateService.instant('FILTER_SEARCH.LIST.SEARCH.CODIFY_STATUS'),
            'tagName',
            SearchEngineConditionCodifyComponent,
            false);
        this.init();
    }

    init(uriParams?: any) {
        this.params = {codify: true};
        this.uriParams = {codify: true};
        this.setUriParams(uriParams);
    }

    updateListAccordingToCodifyStatus() {
        this._broadcastService.send('filterSearchList::search', {page: 1});
    }
}
