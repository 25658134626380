import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../../../core/authentication/authentication.service';
import {CommentApiService} from '../../../../core/api-services/data-set-element/comment/comment.api.service';
export interface CommentInterface {
    id?: number;
    user?: {};
    userId?: number;
    dataSetElementId?: number;
    message?: string;
    createdAt?: string;
    status: number; // status = 0 -> deleted, status = 1 -> active
}

export enum StatusEnum {
    Active = 1,
    Deactivated = 0
}
@Injectable({
    providedIn: 'root'
})
export class StayDetailCommentService {
    constructor(
        private _authenticationService: AuthenticationService,
        private _commentApiService: CommentApiService
    ) {
    }
    async setOne(dataSetElementId: number, comment: CommentInterface): Promise<CommentInterface> {
        try {
            return await this._commentApiService
                .create(dataSetElementId, comment)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async updateOne(dataSetElementId: number, comment: CommentInterface): Promise<CommentInterface> {
        try {
            return await this._commentApiService
                .update(dataSetElementId, comment)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}
