import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionDiagnosticSlugComponent} from './search-engine-condition-diagnostic-slug.component';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {TranslationHelperService} from '../../../../core/services/translation.helper.service';
import {DiagnosticSlugHelperService} from './diagnostic-slug-helper.service';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionDiagnosticSlugService extends SearchEngineCondition {
    isMCOUseCase: boolean;
    isSSRUseCase: boolean;

    constructor(private _translateService: TranslateService,
                private _translationHelperService: TranslationHelperService,
                private _diagnosticSlugHelper: DiagnosticSlugHelperService) {
        super([],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.DIAGNOSTICS_CODED'),
            'diagnosisPresent',
            SearchEngineConditionDiagnosticSlugComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        const defaultCodificationLabels = this.getDefaultCodificationLabels();
        this.params = {
            diagnosticSlug: [],
            diagnosticOperator: 'OR',
            diagnosticCodificationLabel: defaultCodificationLabels?.split(';'),
            source: [],
        };
        this.setApiParams(apiParams);
    }

    getDefaultCodificationLabels(): string {
        return this._diagnosticSlugHelper.getDefaultCodificationLabels(this.isMCOUseCase, this.isSSRUseCase, this._translationHelperService.isFeatureAvailable('DR'));
    }

    convertToParamsFromApiParams(): void {
        this.params.diagnosticSlug = this.apiParams.args.slugs;
        this.params.diagnosticCodificationLabel = this.apiParams.args.codificationLabel;
        this.params.diagnosticOperator = this.apiParams.args.operator;
        this.params.source = this.apiParams.args.source;
    }

    convertToApiParams(): void {
        super.convertToApiParams();

        this.apiParams.args = {
            slugs: [...this.params.diagnosticSlug],
            codificationLabel: [...this.params.diagnosticCodificationLabel],
            operator: this.params.diagnosticOperator,
            source: this.params.source
        };
    }

    isValidApiParams(args: any): boolean {
        return !!(args?.slugs?.length && args?.codificationLabel?.length && args?.operator);
    }
}
