import {Injectable} from '@angular/core';
import {HttpService} from '../../http/http.service';
import {map} from 'rxjs/operators';
import {APIRequestObject} from '../../../modules/search-engine/advanced/search-engine-advanced.service';
import {Observable} from 'rxjs';
import {DataHelperService} from '../../services/data.helper.service';
import {HttpHeaders, HttpResponse} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class FilterSearchApiService {
    private _BASE_URL = 'filter-search';

    constructor(private _http: HttpService) {}

    getAll(urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}`, {params: urlRequestParams})
            .pipe(map(res => {
                return {
                    ...res,
                    total: res.meta.total,
                    lastPage: res.meta.last_page,
                    currentPage: res.meta.current_page
                };
            }));
    }

    getIds(urlRequestParams: any) {
        return this._http
            .get(`${this._BASE_URL}/ids`, {params: urlRequestParams})
            .pipe(map(res => {
                return res;
            }));
    }

    get(filterSearchId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${filterSearchId}`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    create(data: any) {
        return this._http
            .post(this._BASE_URL, data)
            .pipe(map(res => res.data));
    }

    update(filterSearchId: number, data: any) {
        return this._http
            .patch(`${this._BASE_URL}/${filterSearchId}`, data)
            .pipe(map(res => res.data));
    }

    delete(filterSearchId: number) {
        return this._http
            .delete(`${this._BASE_URL}/${filterSearchId}`)
            .pipe(map(res => res.data));
    }

    simulateScore(urlRequestParams?: any) {
        return this._http
            .post(`${this._BASE_URL}/simulate-score`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    /**
     * Exports a file containing filter search data.
     *
     * @param {number[]} filterSearchIds - Array of filter search IDs to export.
     *
     * @returns {Observable<HttpResponse<Blob>>} Observable that emits the HTTP response with a Blob.
     */
    export(filterSearchIds: number[]): Observable<HttpResponse<Blob>> {
        const searchParams = new URLSearchParams();

        filterSearchIds.forEach(id => {
            searchParams.append('ids[]', String(id));
        });

        const url = `${this._BASE_URL}/export-file?${searchParams.toString()}`;

        return this._http.get(url, {
            headers: new HttpHeaders({
                'Accept': 'application/octet-stream'
            }),
            responseType: 'blob',
            observe: 'response'
        });
    }

    /**
     * Imports a file for filter search.
     *
     * @param {FormData} formData - The form data containing the file to import.
     *
     * @returns {Observable<HttpResponse<any>>} Observable that emits the HTTP response.
     */
    import(formData: FormData): Observable<HttpResponse<any>> {
        return this._http
            .post(`${this._BASE_URL}/import-file`, formData, {
                observe: 'response',
            });
    }

    qualityControl(body?: any) {
        DataHelperService.deleteEmptyUndefinedNullFromObject(body);

        return this._http
            .post(`${this._BASE_URL}/quality-control`, body)
            .pipe(map(res => {
                return {
                    ...res,
                    total: res.meta.total,
                    lastPage: res.meta.last_page,
                    currentPage: res.meta.current_page
                };
            }));
    }

    exportQualityControlSearch(body?: any) {
        DataHelperService.deleteEmptyUndefinedNullFromObject(body);

        return this._http
            .post(`${this._BASE_URL}/quality-control/export`,
                body, {responseType: 'blob'})
            ;
    }

    atypia(body?: any) {
        DataHelperService.deleteEmptyUndefinedNullFromObject(body);

        return this._http
            .post(`${this._BASE_URL}/atypia`, body)
            .pipe(map(res => {
                return {
                    ...res,
                    total: res.meta.total,
                    lastPage: res.meta.last_page,
                    currentPage: res.meta.current_page
                };
            }));
    }

    exportAtypiaSearch(body?: any) {
        DataHelperService.deleteEmptyUndefinedNullFromObject(body);

        return this._http
            .post(`${this._BASE_URL}/atypia/export`,
                body, {responseType: 'blob'})
            ;
    }
    getTemporaryFilterSearch(temporaryFilterSearchId: number): Observable<APIRequestObject> {
        return this._http
            .get(`${this._BASE_URL}/temporary/${temporaryFilterSearchId}`)
            .pipe(map(res => res.data.query));
    }
    setWorklistUserFilterSearch(filterSearchId: number, urlRequestParams?: any) {
        return this._http
            .post(`${this._BASE_URL}/${filterSearchId}/user/add`, {userIds: urlRequestParams})
            .pipe(map(res => res.roles));
    }

    deleteWorklistUserFilterSearch(filterSearchId: number, urlRequestParams?: any[]) {
        return this._http
            .delete(`${this._BASE_URL}/${filterSearchId}/user/${urlRequestParams}`)
            .pipe(map(res => res.data));
    }
}
