<mat-form-field class="width-115">
    <mat-select [placeholder]="title | translate"
                [panelClass]="['mat-' + colorService.getColorAccordingToCurrentState()]"
                [(ngModel)]="condition.service?.params.codify"
                (ngModelChange)="updateListAccordingToCodifyStatus()"
    >
        <mat-option [value]="''">{{'FILTER_SEARCH.LIST.SEARCH.ALL' | translate}}</mat-option>
        <mat-option [value]="true">{{'FILTER_SEARCH.LIST.SEARCH.ACTIVATED' | translate}}</mat-option>
        <mat-option [value]="false">{{'FILTER_SEARCH.LIST.SEARCH.DISABLED' | translate}}</mat-option>
    </mat-select>
</mat-form-field>
