import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionFilterSearchTypeComponent} from './search-engine-condition-filter-search-type.component';
import {SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionFilterSearchTypeService extends SearchEngineCondition {
    constructor(private _translateService: TranslateService) {
        super(['tagName'],
            _translateService.instant('FILTER_SEARCH.FILTER_SEARCH_TYPE'),
            'tagName',
            SearchEngineConditionFilterSearchTypeComponent,
            false);
        this.init();
    }

    init(uriParams?: any) {
        // j'ai retirer dependency car ce truc est pas dispo pour la version de prod, c'est bon seulement sur le dev
        this.params = {tagName: ['diagnosis', 'act', 'worklist', 'none']};
        this.uriParams = {tagName: 'diagnosis,act,worklist,none'};
        this.setUriParams(uriParams);
    }

    convertToUriParams() {
        if (this.params.tagName) {
            this.uriParams.tagName = this.params.tagName.join(',');
        }
    }

    convertToParams() {
        if (this.uriParams.tagName) {
            this.params.tagName = this.uriParams.tagName.split(',');
        }
    }

    isValid(fields?: any): boolean {
        return true;
    }
}
