import {Component, OnInit} from '@angular/core';
import {QualifactControlService} from '../../../qualifact-control/qualifact-control-service';
import {QualifactControl} from '../../../qualifact-control/qualifact-control.model';
import {LayoutService} from '../../../layout/layout.service';

@Component({
    selector: 'ct-search-engine-condition-qualifact',
    templateUrl: './search-engine-condition-qualifactSmr.component.html',
    styleUrls: ['./search-engine-condition-qualifactSmr.component.scss']
})
export class SearchEngineConditionQualifactSmrComponent implements OnInit {
    condition: any;
    qualifactControls: QualifactControlService[] = [];
    isLoading: boolean;
    toggleAllMapCallback = (element: any) => element?.id;

    constructor(
        private _quaifactControlService: QualifactControlService,
        private _layoutService: LayoutService) {
    }

    ngOnInit() {
        this._loadAllQualifactControls();
    }

    trackByFn(index: number) {
        return index;
    }

    private _mapToGenericData(qualifactControls: QualifactControl[]): any[] {
        if (qualifactControls) {
            return qualifactControls.map(qualifactControl => {
                const text = `${qualifactControl.code} ${qualifactControl.label}`;
                const value = this.toggleAllMapCallback(qualifactControl);
                return {...qualifactControl, text, value};
            });
        }
        return [];
    }


    private async _loadAllQualifactControls(): Promise<void> {
        try {
            this._layoutService.startLoading();
            this.isLoading = true;
            const qualifactControls = await this._quaifactControlService.loadAllQualifactControls(
                {
                    'filter[care_type]': 'SMR'
                }
            );
            this.qualifactControls = this._mapToGenericData(qualifactControls);
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            console.error(e);
            throw e;
        } finally {
            this._layoutService.stopLoading();
        }
    }
}
