import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';
import {SearchEngineConditionCodificationLabelPresenceComponent} from './search-engine-condition-codification-label-presence.component';

@Injectable()
export class SearchEngineConditionCodificationLabelPresenceService extends SearchEngineCondition {
    useCase: string;

    constructor(private _translateService: TranslateService) {
        super([],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.CODIFICATION_LABEL_PRESENCE'),
            'codificationLabelPresence',
            SearchEngineConditionCodificationLabelPresenceComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {
            values: [],
            operator: 'AND'
        };
        this.setApiParams(apiParams);
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {
            values: [...this.params.values],
            operator: this.params.operator
        };
    }

    isValidApiParams(args: any): boolean {
        return !!(args?.values?.length && args?.operator);
    }
}
