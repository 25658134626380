import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpService} from '../../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class UserApiService {
    private _BASE_URL = 'user';

    constructor(private _http: HttpService) {}

    getAll(urlRequestParams?: any) {
        return this._http
            .get(this._BASE_URL, {params: urlRequestParams})
            .pipe(map(res => res.data.map(obj => obj)));
    }

    get(userId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${userId}`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    create(data: any) {
        const res = {user: null, link: null};
        return this._http
            .post(this._BASE_URL, data)
            .pipe(map(obj => {
                Object.keys(obj).forEach(key => {
                    if (key === 'data') {
                        res.user = obj[key];
                    }
                    if (key === 'link') {
                        res.link = obj[key];
                    }
                });
                return res;
            }));
    }

    update(userId: number, data: any) {
        return this._http
            .patch(`${this._BASE_URL}/${userId}`, data)
            .pipe(map(res => res.data));
    }

    destroy(userId: number) {
        return this._http
            .delete(`${this._BASE_URL}/${userId}`);
    }

    updatePassword(userId: number, data: any) {
        return this._http
            .patch(`${this._BASE_URL}/${userId}/update-password`, data);
    }

    updateOwnPassword(userId: number, data: any) {
        return this._http
            .patch(`${this._BASE_URL}/${userId}/update-own-password`, data);
    }

    loadConnectionLogs(urlRequestParams ?: any) {
        return this._http
            .get(`${this._BASE_URL}/log`, {params: urlRequestParams});
    }
}
