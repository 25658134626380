<div class="row">
    <div class="col no-padding">
        <ct-diagnostic-search [(param)]="condition.service.params.diagnosticMissingSlug"></ct-diagnostic-search>
    </div>
</div>

<div class="flex items-center mb-2">
    <ng-container *ngIf="isMCOUseCase">
        <mat-checkbox class="margin-right-10"
                      [color]="colorService.getColorAccordingToCurrentState()"
                      [(ngModel)]="canUseCodificationLabels.DP"
                      (change)="updateDiagnosticMissingCodificationLabelParam('DP')">
            <span>{{'ACRONYM.DP' | translate}}</span>
        </mat-checkbox>

        <mat-checkbox class="margin-right-10"
                      [color]="colorService.getColorAccordingToCurrentState()"
                      [(ngModel)]="canUseCodificationLabels.DR"
                      (change)="updateDiagnosticMissingCodificationLabelParam('DR')">
            <span>{{'ACRONYM.DR' | translate}}</span>
        </mat-checkbox>
    </ng-container>

    <ng-container *ngIf="isSSRUseCase">
        <mat-checkbox class="margin-right-10"
                      [color]="colorService.getColorAccordingToCurrentState()"
                      [(ngModel)]="canUseCodificationLabels.MP"
                      (change)="updateDiagnosticMissingCodificationLabelParam('MP')">
            <span>{{'ACRONYM.MP' | translate}}</span>
        </mat-checkbox>

        <mat-checkbox class="margin-right-10"
                      [color]="colorService.getColorAccordingToCurrentState()"
                      [(ngModel)]="canUseCodificationLabels.AE"
                      (change)="updateDiagnosticMissingCodificationLabelParam('AE')">
            <span>{{'ACRONYM.AE' | translate}}</span>
        </mat-checkbox>
    </ng-container>

    <mat-checkbox
        class="margin-right-10"
        [color]="colorService.getColorAccordingToCurrentState()"
        [(ngModel)]="canUseCodificationLabels.DA"
        (change)="updateDiagnosticMissingCodificationLabelParam('DA')"
    >
        <span>{{ 'ACRONYM.DA' | translate}}</span>
    </mat-checkbox>

    <div class="w-[1px] h-[20px] border-l border-white/20 mx-2"></div>

    <div
        *ngIf="condition.service.validateWithCheckbox"
        class="inline-block"
    >
        <div class="col no-padding">
            <mat-checkbox
                class="margin-left-10 margin-right-10"
                [color]="colorService.getColorAccordingToCurrentState()"
                [(ngModel)]="onlyStayWithRevaluation"
                (change)="updateDiagnosticMissingCodificationLabelParam('DA')"
            >
                <span>{{ 'STAY.DISPLAY_REVALUATION' | translate }}</span>
            </mat-checkbox>
        </div>
    </div>
</div>
