import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import * as _ from 'lodash-es';
import {TranslateService} from '@ngx-translate/core';


export interface QualifactControlValue {
    id: number;
    code: string;
    label: string;
    description: string;
}

@Component({
    selector: 'ct-qualifact-control-search',
    templateUrl: './qualifact-control-search.component.html',
    styleUrls: ['./qualifact-control-search.component.scss']
})
export class QualifactControlSearchComponent implements OnInit {
    @ViewChild('qualifactControlInput', { static: true }) qualifactControlInput: ElementRef<HTMLInputElement>;

    private _param: QualifactControlValue[];

    @Input() qualifactControls: any[] = [];
    @Input() set param(newParam: QualifactControlValue[]) {
        if (newParam && this._param !== newParam) {
            this._param = newParam;
            this._initSelectedQualifactControls();
        }
    }

    @Output() paramChange: EventEmitter<QualifactControlValue[]> = new EventEmitter<QualifactControlValue[]>();

    selectedQualifactControls: any[] = [];
    searchedQualifactControls$: Observable<any[]>;
    formControl: FormControl = new FormControl();

    constructor(private _translateService: TranslateService) { }

    ngOnInit(): void {
        this.searchedQualifactControls$ =
            this.formControl.valueChanges
                .pipe(
                    startWith(''),
                    map(value => this._getFilteredQualifactControls(value)),
                    map(value => _.orderBy(value, [qControl => qControl.name.toLowerCase()], ['asc'])));
        this._initSelectedQualifactControls();
    }

    private _getFilteredQualifactControls(query: string): any[] {
        if (this.qualifactControls) {
            const qualifactControls = this.qualifactControls
                .map(qualifactControl => ({
                    ...qualifactControl,
                    name: qualifactControl.code || qualifactControl.label || ''
                }));
            if (query?.length > 0) {
                return qualifactControls.filter(qualifactControl => qualifactControl.name.toLowerCase().includes(query.toLowerCase()));
            }
            return qualifactControls;
        }
        return [];
    }

    private _initSelectedQualifactControls(): void {
        if (this.qualifactControls &&
            this._param) {
            this.selectedQualifactControls = this.qualifactControls.filter(qualifactControl => {
                const qualifactControlFromParam = this._param.find(el => el === qualifactControl.code);
                return !!qualifactControlFromParam;
            });
        }
    }

    selectQualifactControl(event: any): void {
        if (event) {
            if (!this.selectedQualifactControls) {
                this.selectedQualifactControls = [];
            }
            const searchedQualifactControl = event.option.value || {};
            const qualifactControl = this.selectedQualifactControls.find(el => el.id === searchedQualifactControl.id);
            if (!qualifactControl) {
                if (searchedQualifactControl !== {}) {
                    this.selectedQualifactControls.push(searchedQualifactControl);
                    this.paramChange
                        .emit(this.selectedQualifactControls.map(selectedQC => selectedQC.code));
                }
            }
            this.qualifactControlInput.nativeElement.value = '';
            this.formControl.setValue(null);
        }
    }

    removeChip(qualifactControlToRemove: any): void {
        if (this.selectedQualifactControls &&
            qualifactControlToRemove) {
            this.selectedQualifactControls = this.selectedQualifactControls.filter(el => el.id !== qualifactControlToRemove.id);
            this.paramChange
                .emit(this.selectedQualifactControls.map(selectedQC => selectedQC.code));
        }
    }

    getTooltip(description: string): string {
        return description;
    }

    trackByFn(index: number, item: any): number | null {
        return item ? item.id : null;
    }
}
