import {Injectable} from '@angular/core';
import {SearchEngineHelperService} from '../search-engine.helper.service';
import {RuleSet} from './query-builder/search-engine-advanced-query-builder.component';
import {StateService} from '@uirouter/core';
import {BroadcastService} from '../../../core/services/broadcast.service';
import {ApiCriteria} from '../condition/search-engine.condition';
import {FilterSearchApiService} from '../../../core/api-services/filter-search/filter-search.api.service';
import {SearchEngineConditionFactoryService} from '../condition/search-engine-condition-factory.service';

export interface APIRequestObject {
    type: string;
    args: {
        operator: string;
        criteria: any[]
    };
}

@Injectable({
    providedIn: 'root'
})
export class SearchEngineAdvancedService {
    private _searchConditions: any[]; // SearchConditions which are not in the advanced query builder
    private _currentAdvancedSearch: RuleSet;

    constructor(public $state: StateService,
                public searchEngineHelperService: SearchEngineHelperService,
                private _searchEngineConditionFactoryService: SearchEngineConditionFactoryService,
                private _filterSearchApiService: FilterSearchApiService,
                private _broadcastService: BroadcastService) {
        this._initData();
    }

    private _availableSearchConditions: any[];

    get availableSearchConditions(): any[] {
        return this._availableSearchConditions;
    }

    private _filterSearchQuery: APIRequestObject;

    get filterSearchQuery(): APIRequestObject {
        return this._filterSearchQuery ?? JSON.parse(sessionStorage.getItem('filterSearchQuery'));
    }

    set filterSearchQuery(value: APIRequestObject) {
        // store query if user refresh the page to retrieve it in the detail component
        sessionStorage.setItem('filterSearchQuery', JSON.stringify(
            value
        ));
        this._filterSearchQuery = value;
    }

    private _temporaryFilterSearchId: number;

    get temporaryFilterSearchId(): number {
        return this._temporaryFilterSearchId;
    }

    set temporaryFilterSearchId(id: number) {
        this._temporaryFilterSearchId = id;
    }

    private _temporaryFilterSearchPatientId: number;

    get temporaryFilterSearchPatientId(): number {
        return this._temporaryFilterSearchPatientId;
    }

    set temporaryFilterSearchPatientId(id: number) {
        this._temporaryFilterSearchPatientId = id;
    }

    get advancedSearch(): RuleSet {
        return this._currentAdvancedSearch;
    }

    async setAdvancedSearchFromApiRequestObject(apiRequestObject: APIRequestObject): Promise<void> {
        try {
            const advancedConditionObj = apiRequestObject?.args?.criteria?.find(el => el.type === 'bool');
            this._currentAdvancedSearch = await this._buildAdvancedSearchFromApiRequestObject(advancedConditionObj);
            this.initDefaultSearchConditions(apiRequestObject);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async setAdvancedSearchFromTemporaryFilterSearch(temporaryFilterSearchId?: number): Promise<void> {
        try {
            const apiRequestObject = await this._filterSearchApiService
                .getTemporaryFilterSearch(temporaryFilterSearchId)
                .toPromise();
            await this.setAdvancedSearchFromApiRequestObject(apiRequestObject);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    initDefaultSearchConditions(apiRequestObject: APIRequestObject): void {
        const defaultCriterion = apiRequestObject?.args?.criteria?.filter(el => el.type !== 'bool');
        defaultCriterion?.forEach(criteria => {
            const searchCondition = this.getSearchCondition(criteria.type);
            searchCondition?.service?.init(null, criteria);
        });
    }

    initSearchConditions(): void {
        this._searchConditions = [];
        this._availableSearchConditions.forEach(availableSearchCondition => {
            // We want establishment in default + advanced filter
            if (!availableSearchCondition.isAdvanced || availableSearchCondition.slug === 'establishment' || availableSearchCondition.slug === 'ipp') {
                this._addSearchCondition(availableSearchCondition);
            }
        });
    }

    /**
     * Update availableSearchConditions array with conditionService
     * and update its data if necessary
     * @param conditionService
     * @param data is set in the search services
     */
    addAvailableSearchCondition(conditionService: any, data?: any): void {
        this._availableSearchConditions = this.searchEngineHelperService.addAvailableSearchCondition(this._availableSearchConditions, conditionService, data);
    }

    addDivider(id: number): void {
        this._availableSearchConditions = this.searchEngineHelperService.addDivider(this._availableSearchConditions, id);
    }

    saveCurrentSearch(advancedSearch: RuleSet): void {
        this._currentAdvancedSearch = advancedSearch;
    }

    getSearchCondition(searchConditionSlug: string): any {
        return this.searchEngineHelperService.getSearchCondition(this._searchConditions, searchConditionSlug);
    }

    getDefaultSearchCondition(): any {
        return {
            service: {
                slug: '',
                default: true
            }
        };
    }

    changeSearchCondition(searchCondition: any, availableSearchConditionSlug: any): void {
        if (searchCondition?.service && availableSearchConditionSlug) {
            const tmp = this.searchEngineHelperService.getAvailableSearchCondition(this._availableSearchConditions, availableSearchConditionSlug);
            if (tmp) {
                this.searchEngineHelperService.setSearchConditionProperties(tmp, searchCondition.service);
                if (tmp.slug === 'diagnosisMissing') {
                    searchCondition.service.init(null, tmp.validateWithCheckbox);
                } else {
                    searchCondition.service.init();
                }
            }
        }
    }

    getApiRequestObject(useCase?: string): any {
       const advancedApiObject = this._buildAdvancedApiRequestObject(this._currentAdvancedSearch?.condition, this._currentAdvancedSearch?.rules);
       if (useCase) {
            return {
                query: {
                    type: 'bool',
                    args: {
                        operator: 'AND',
                        criteria: [
                            this._getTypeCriterion(useCase),
                            ...this._getDefaultApiCriterion(),
                            advancedApiObject
                        ]
                    }
                }
            };
        } else {
            return {
                query: {
                    type: 'bool',
                    args: {
                        operator: 'AND',
                        criteria: [
                            ...this._getDefaultApiCriterion(),
                            advancedApiObject
                        ]
                    }
                }
            };
        }
    }

    resetData(): void {
        this._initData();
    }

    public filterSearchQueryGetCondition(conditionSlug: string, criterion: any[] = this.filterSearchQuery?.args?.criteria): any {
        let data: any;
        criterion?.some((criteria) => {
            if (criteria.type === 'bool') {
                data = this.filterSearchQueryGetCondition(conditionSlug, criteria?.args?.criteria);
                return !!data;
            } else if (criteria.type === conditionSlug) {
                data = criteria;
                return true;
            }
        });
        return data;
    }

    public filterSearchQueryGetAllCondition(conditionSlug: string, criterion: any[] = this.filterSearchQuery?.args?.criteria): any[] {
        let data = [];
        criterion?.forEach((criteria) => {
            if (criteria.type === 'bool') {
                const nestedData = this.filterSearchQueryGetAllCondition(conditionSlug, criteria?.args?.criteria);
                data = [...data, ...nestedData];
            } else if (criteria.type === conditionSlug) {
                data.push(criteria);
            }
        });
        return data;
    }

    public getFilterSearchSlugsByConditionSlug(conditionSlug: string, criterion: any[] = this.filterSearchQuery?.args?.criteria, operator = null): string[] {
        let data: any[] = [];
        criterion?.forEach((criteria) => {
            if (criteria?.type === conditionSlug) {
                if (!operator || (operator && criteria?.operator === operator)) {
                    data.push(...criteria?.args?.slugs);
                }
            } else if ( criteria.type === 'bool' ) {
                const recursiveData = this.getFilterSearchSlugsByConditionSlug(conditionSlug, criteria?.args?.criteria);
                data = [...data, ...recursiveData];
            }
        });

        return data;
    }


    public getFilterSearchSlugsByConditionSecondType(
        conditionType: string,
        criterion: any[] = this.filterSearchQuery?.args?.criteria,
        operator: string | null = null
    ): string[] {
        let data: any[] = [];
        criterion?.forEach((criteria) => {
            if (criteria?.args?.type === conditionType) {
                if (!operator || (operator && criteria?.args?.operator === operator)) {
                    data.push(criteria);
                }
            } else if ( criteria.type === 'bool' ) {
                const recursiveData = this.getFilterSearchSlugsByConditionSecondType(conditionType, criteria?.args?.criteria);
                data = [...data, ...recursiveData];
            }
        });
        return data;
    }


    public removeFilterSearchQueryBySlug(conditionSlug: string, filterSearchQuery): any {
        if (filterSearchQuery.args && filterSearchQuery.args.criteria) {
            filterSearchQuery.args.criteria.forEach((criteria, index) => {
                if (criteria.type === 'bool') {
                    this.removeFilterSearchQueryBySlug(conditionSlug, criteria);
                } else if (criteria.type === conditionSlug) {
                    filterSearchQuery.args.criteria.splice(index, 1);
                }
            });
        }
        return filterSearchQuery;
    }

    public removeFilterSearchQueryNotInWhiteList(whiteList: string[], filterSearchQuery): any {
        if (filterSearchQuery?.args?.criteria) {
            filterSearchQuery.args.criteria = filterSearchQuery.args.criteria.filter((criteria) => {
                if (criteria.type === 'bool') {
                    this.removeFilterSearchQueryNotInWhiteList(whiteList, criteria);
                    return true; // Keep bool criteria since we need to process its nested criteria
                } else {
                    return whiteList.includes(criteria.type);
                }
            });
        }
        return filterSearchQuery;
    }

    public filterSearchQueryHasCondition(conditionSlug: string, criterion: any[] = this.filterSearchQuery?.args?.criteria): boolean {
        return !!this.filterSearchQueryGetCondition(conditionSlug, criterion);
    }

    public getQueryConditionIndex(query: any, condition) {
        if (query?.args?.criteria) {
            return query.args.criteria.findIndex(x => x.type === condition);
        }
        return -1;
    }

    resetSearch(): void {
        this._searchConditions.forEach(searchCondition => {
            if (searchCondition.service.slug === 'codificationStatus') {
                searchCondition.service.init(null, false);
            } else {
                searchCondition.service.init();
            }
        });
        //  this._initData(false);
        this._broadcastService.send('searchEngineAdvancedService::resetSearch');
    }

    private _initData(emptyAvailableSearchConditions: boolean = true): void {
        if (emptyAvailableSearchConditions) {
            this._availableSearchConditions = [];
        }
        this._searchConditions = [];
        this._currentAdvancedSearch = this._getDefaultCurrentAdvancedSearch();
    }

    private _getDefaultCurrentAdvancedSearch(): RuleSet {
        return {condition: 'AND', rules: [this.getDefaultSearchCondition()]};
    }

    private _addSearchCondition(service: any): void {
        if (service?.init) {
            service.init();
            this._searchConditions.push({service});
        }
    }

    private _getDefaultApiCriterion(): ApiCriteria[] {
        return this._searchConditions.reduce((accumulator, currentValue) => {
            const apiParams: ApiCriteria = currentValue.service?.getApiParams();
            if (apiParams?.args) {
                return [...accumulator, apiParams];
            }
            return [...accumulator];
        }, []);
    }

    private _getTypeCriterion(useCase: string) {
        if (useCase) {
            return {
                type: 'type',
                args: {
                    values: useCase.split(';')
                }
            };
        }
        return null;
    }

    private _buildAdvancedApiRequestObject(condition: string, rules: (RuleSet | any)[]): APIRequestObject | {} {
        if (condition &&
            rules) {
            return {
                type: 'bool',
                args: {
                    operator: condition,
                    criteria: this._getCriteria(rules)
                }
            };
        }
    }

    private _getCriteria(rules: (RuleSet | any)[]): any[] {
        return rules?.reduce((accumulator, rule) => {
            if (rule?.condition) {
                return [...accumulator, this._buildAdvancedApiRequestObject(rule.condition, rule.rules)];
            } else if (rule?.service?.getApiParams) {
                if (['documentTypePresence'].includes(rule.service.slug)) {
                    // Because we have multiple criterion inside one service
                    const apiParams: ApiCriteria = rule.service?.getApiParams();

                    return apiParams ? [...accumulator, ...apiParams.args.values] : [...accumulator];
                } else {
                    const apiParams: ApiCriteria = rule.service?.getApiParams();

                    return apiParams ? [...accumulator, apiParams] : [...accumulator];
                }
            }
            return accumulator;
        }, []) || [];
    }

    private async _buildAdvancedSearchFromApiRequestObject(apiRequestObject: APIRequestObject): Promise<RuleSet> {
        const rules = await apiRequestObject?.args?.criteria?.reduce(async (accumulatorPromise, criteria) => {
            const accumulator = await accumulatorPromise;
            if (criteria.type === 'bool') {
                return [...accumulator, await this._buildAdvancedSearchFromApiRequestObject(criteria)];
            } else {
                const searchCondition = await this._getMatchingAvailableSearchCondition(criteria);
                return searchCondition ? [...accumulator, {service: searchCondition}] : [...accumulator];
            }
        }, Promise.resolve([])) || [];
        return {
            condition: apiRequestObject?.args.operator,
            rules: rules.length ? rules : [this.getDefaultSearchCondition()],
            isCollapsed: false
        };
    }

    private _checkConditionSlug(criteria: ApiCriteria): string {
        if (criteria.type === 'actPresence') {
            if (criteria.args.type !== 'csarr') {
                return criteria.args.operator === 'OR' ? 'actSlug' : 'actMissingSlug';
            } else {
                return criteria.args.operator === 'OR' ? 'actSlugCSARR' : 'actMissingSlugCSARR';
            }
        } {
            if (criteria.type === 'dependency') {
                return criteria.type = criteria.args.type;
            }
        }
        return criteria.type;
    }

    private async _getMatchingAvailableSearchCondition(criteria: APIRequestObject): Promise<any> {
        const availableSearchCondition = this._availableSearchConditions.find(condition => {
            return condition.slug === this._checkConditionSlug(criteria) && condition.isValidApiParams(criteria.args);
        });
        if (availableSearchCondition &&
            !this.getSearchCondition(availableSearchCondition.slug)) {
            const service = this._searchEngineConditionFactoryService.getNewSearchConditionInstance(availableSearchCondition.slug);
            if (service.slug === 'structuredData') {
                await service?.init(null, criteria);
            } else {
                service?.init(null, criteria);
            }
            if (service &&
                !availableSearchCondition.isAdvanced) {
                this._searchConditions.push({service});
            }
            return service;
        }
        return null;
    }
}
