import {HttpResponse} from '@angular/common/http';

export type FileMimeType =
    | 'text/csv'
    | 'application/json'
    | 'application/xml'
    | 'application/pdf'
    | 'text/plain'
    | 'text/html'
    | 'image/png'
    | 'image/jpeg'
    | 'application/octet-stream';

/**
 * Launches a file download for the given data.
 *
 * @param blob
 * @param fileName
 * @param type
 */
export function downloadFile(blob: BlobPart, fileName: string, type: FileMimeType = 'text/csv'): void {
    if (!blob) {
        return;
    }

    const file = new Blob([blob], {type: type});
    const url = window.URL.createObjectURL(file);
    const a = document.createElement('a');

    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);

    setTimeout(() => {
        window.URL.revokeObjectURL(url);
    }, 1);
}

/**
 * Requests a download from the given response.
 *
 * @param response
 */
export async function requestDownload(response: HttpResponse<Blob>): Promise<{ blob: Blob, filename: string|null }> {
    const contentDisposition = response.headers.get('content-disposition');
    const blob = response.body;

    let filename = null;
    if (contentDisposition && contentDisposition.indexOf('filename=') !== -1) {
        const match = contentDisposition.match(/filename=([^;]+)/);

        if (match && match[1]) {
            filename = match[1].trim().replace(/['"]/g, '');
        }
    }

    return { blob, filename };
}
