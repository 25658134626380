import {Component, OnInit} from '@angular/core';
import {TranslationHelperService} from '../../../../core/services/translation.helper.service';
import {ColorService} from '../../../../core/services/color.service';
import {StateService} from '@uirouter/core';
import {CodificationLabelEnum} from '../../../stay/shared/codification-label-enum';
import {ConfigurationService} from '../../../configuration/configuration.service';

@Component({
    selector: 'ct-search-engine-condition-diagnostic-missing',
    templateUrl: './search-engine-condition-diagnostic-missing.component.html',
    styleUrls: ['./search-engine-condition-diagnostic-missing.component.scss']
})
export class SearchEngineConditionDiagnosticMissingComponent implements OnInit {
    private _codificationLabels = {
        DP: '',
        DA: '',
        DR: '',
        MP: '',
        AE: '',
    };

    canUseCodificationLabels = {
        DP: false,
        DR: false,
        DA: false,
        MP: false,
        AE: false
    };
    canDisplayDR: boolean;
    onlyStayWithRevaluation = false;

    condition: any;
    isMCOUseCase: boolean;
    isSSRUseCase: boolean;

    constructor(public $state: StateService,
                public colorService: ColorService,
                private _translationHelperService: TranslationHelperService,
                private _configurationService: ConfigurationService) { }

    ngOnInit(): void {
        this.isSSRUseCase = this._configurationService.appTypeIs('SSR') || this._configurationService.appTypeIsMCOAndSSR();
        this.isMCOUseCase = this._configurationService.appTypeIs('MCO') || this._configurationService.appTypeIsMCOAndSSR();
        this._initCodificationLabels();
    }

    private _initCodificationLabelsMCO(): void {
        this._codificationLabels.DP = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DP) || 'DP';
        this._codificationLabels.DR = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DR) || 'DR';
        if (this.condition.service.params.diagnosticMissingCodificationLabel) {
            this.canUseCodificationLabels.DP = this.condition.service.params.diagnosticMissingCodificationLabel.includes(this._codificationLabels.DP);
            this.canUseCodificationLabels.DR = this.condition.service.params.diagnosticMissingCodificationLabel.includes(this._codificationLabels.DR);
        }
    }

    private _initCodificationLabelsSSR(): void {
        this._codificationLabels.MP = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.MP) || 'MP';
        this._codificationLabels.AE = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.AE) || 'AE';
        if (this.condition.service.params.diagnosticMissingCodificationLabel) {
            this.canUseCodificationLabels.MP = this.condition.service.params.diagnosticMissingCodificationLabel.includes(this._codificationLabels.MP);
            this.canUseCodificationLabels.AE = this.condition.service.params.diagnosticMissingCodificationLabel.includes(this._codificationLabels.AE);
        }
    }

    private _initCodificationLabels(): void {
        if (this.isMCOUseCase) {
            this._initCodificationLabelsMCO();
        }
        if (this.isSSRUseCase) {
            this._initCodificationLabelsSSR();
        }
        this._codificationLabels.DA = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DA) || 'DA';
        this.canUseCodificationLabels.DA = this.condition.service.params.diagnosticMissingCodificationLabel ?
            this.condition.service.params.diagnosticMissingCodificationLabel.includes(this._codificationLabels.DA) : true;
        this.canDisplayDR = this._translationHelperService.isFeatureAvailable('DR');
    }

    updateDiagnosticMissingCodificationLabelParam(useCase: string): void {
        if (!this.condition.service.params.diagnosticMissingCodificationLabel) {
            return;
        }

        const codificationLabel = this._codificationLabels[useCase];
        if (!codificationLabel) {
            return;
        }
        if (this.canUseCodificationLabels[useCase]) {
            this.condition.service.params.diagnosticMissingCodificationLabel.push(codificationLabel);
        } else {
            const index = this.condition.service.params.diagnosticMissingCodificationLabel.indexOf(codificationLabel);

            if (index > -1) {
                this.condition.service.params.diagnosticMissingCodificationLabel.splice(index, 1);
            }
        }

        this.condition.service.params.diagnosticAutoPricing = this.onlyStayWithRevaluation;
    }
}
