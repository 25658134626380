<ng-container *ngIf="isMCOUseCase">
    <ct-search-including-excluding-operator class="margin-right-10"
                                            [(param)]="condition.service.params.operator"
                                            [isAllOperator]="true"
    ></ct-search-including-excluding-operator>
    <mat-checkbox class="margin-right-10"
                  [color]="colorService.getColorAccordingToCurrentState()"
                  [(ngModel)]="canUseCodificationLabels.DP"
                  (change)="updateCodificationLabelPresenceParam('DP')">
        <span>{{'ACRONYM.DP' | translate}}</span>
    </mat-checkbox>

    <mat-checkbox class="margin-right-10"
                  *ngIf="canDisplayDR"
                  [color]="colorService.getColorAccordingToCurrentState()"
                  [(ngModel)]="canUseCodificationLabels.DR"
                  (change)="updateCodificationLabelPresenceParam('DR')">
        <span>{{'ACRONYM.DR' | translate}}</span>
    </mat-checkbox>
</ng-container>

<ng-container *ngIf="isSSRUseCase">

    <mat-checkbox class="margin-right-10"
                  [color]="colorService.getColorAccordingToCurrentState()"
                  [(ngModel)]="canUseCodificationLabels.MP"
                  (change)="updateCodificationLabelPresenceParam('MP')">
        <span>{{'ACRONYM.MP' | translate}}</span>
    </mat-checkbox>

    <mat-checkbox class="margin-right-10"
                  [color]="colorService.getColorAccordingToCurrentState()"
                  [(ngModel)]="canUseCodificationLabels.AE"
                  (change)="updateCodificationLabelPresenceParam('AE')">
        <span>{{'ACRONYM.AE' | translate}}</span>
    </mat-checkbox>
</ng-container>

<mat-checkbox class="margin-right-10"
              [color]="colorService.getColorAccordingToCurrentState()"
              [(ngModel)]="canUseCodificationLabels.DA"
              (change)="updateCodificationLabelPresenceParam('DA')">
    <span>{{'ACRONYM.DA' | translate}}</span>
</mat-checkbox>
