import { CommonModule } from '@angular/common';
import { StayUserButtonComponent } from './stay-user-button.component';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    declarations: [
        StayUserButtonComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatTooltipModule
    ],
    exports: [
        StayUserButtonComponent
    ]
})

export class StayUserButtonModule {}
