import {Component, OnInit} from '@angular/core';
import {StateService} from '@uirouter/core';
import {AuthenticationService} from '../../authentication/authentication.service';
import {LayoutService} from '../../../modules/layout/layout.service';
import {FileDisplayDialogComponent} from '../../../shared/components/file-display/dialog/file-display-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {TranslationHelperService} from '../../services/translation.helper.service';
import {VersionDisplayDialogService} from '../../../modules/version-display-dialog/version-display-dialog.service';
import {AlertService} from '../../../modules/alert/alert.service';
import {DataWebApiService} from '../../api-services/data-web/data-web.api.service';
import {SnackBarService} from '../../services/snack-bar.service';
import { Renderer2 } from '@angular/core';
import {HttpService} from '../../http/http.service';
import {ConfigurationService} from '../../../modules/configuration/configuration.service';
import {onUnreadCountChange, show} from '@intercom/messenger-js-sdk';
import {DocumentTypeDocumentListDisplayDialogComponent} from '../../../shared/components/document-type-document-list-display/dialog/document-type-document-list-display-dialog.component';

interface ModuleToSubscribe {
    stateName: string;
    alertName: string;
}

@Component({
    selector: 'ct-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
    private _currentUser: any;
    private _modulesToSubscribe: ModuleToSubscribe[] = [
        {
            stateName: 'quality-control',
            alertName: 'quality_control_new_stay'
        }
    ];

    userPicture: string;
    canDisplayDocumentation: boolean;
    canDisplayAlertList: boolean;
    canDisplayMLGenericCall: boolean;
    canDisplayOrbisButton: boolean;
    intercomEnabled: boolean;
    unreadIntercomNotifications?: string = null;

    constructor(
        public $state: StateService,
        private _matDialog: MatDialog,
        private _authenticationService: AuthenticationService,
        private _alertService: AlertService,
        private _versionDisplayDialog: VersionDisplayDialogService,
        private _translateService: TranslateService,
        private _translationHelperService: TranslationHelperService,
        private _layoutService: LayoutService,
        private _dataWebApiService: DataWebApiService,
        private _snackBarService: SnackBarService,
        private renderer: Renderer2,
        private _httpService: HttpService,
        private _configuration: ConfigurationService,
    ) {
    }

    ngOnInit() {
        this._currentUser = JSON.parse(localStorage.getItem('user')) || {};
        this.userPicture = this._currentUser.picture;

        this._initAvailableFeatures();
        this._initData();

        this.initIntercomUnreadCount();
    }

    /**
     * Initializes the Intercom unread count system.
     *
     * @private
     * @returns {void}
     */
    private initIntercomUnreadCount(): void {
        this.pullIntercomUnreadCount();

        // Display Intercom Messenger when first loading the page if user
        // has unread notification. Also set unread notifications count to null;
        if (this.unreadIntercomNotifications) {
            show();
            this.unreadIntercomNotifications = null;
        }

        setInterval(() => {
            this.pullIntercomUnreadCount();
        }, 1000 * 60);
    }

    /**
     * Retrieves the current unread notification count from Intercom.
     *
     * @private
     * @returns {void}
     */
    private pullIntercomUnreadCount(): void {
        onUnreadCountChange((count: number) => {
            const strCount = count > 9
                ? '9+'
                : String(count);

            this.unreadIntercomNotifications = count
                ? strCount
                : null;
        });
    }

    get currentSubscribableModule(): ModuleToSubscribe {
        return this._modulesToSubscribe.find(el => el.stateName === this.$state.current.name);
    }

    get logoUrl(): string {
        return this._httpService.isResearchUrl(this.$state.href(this.$state.current.name, this.$state.params))
            ? './assets/images/i4h-research.svg'
            : './assets/images/i4h.svg';
    }

    get isResearch(): boolean {
        return this._httpService.isResearchUrl(
            this.$state.href(this.$state.current.name, this.$state.params)
        );
    }

    private _initAvailableFeatures(): void {
        this.canDisplayDocumentation = this._translationHelperService.isFeatureAvailable('documentation');
    }

    private _initData(): void {
        this.intercomEnabled = this._configuration.getConfigurationContent('front', 'INTERCOM.enabled');

        if (this._currentUser) {
            this.canDisplayAlertList = this._currentUser.id === 1 || this._authenticationService.hasRole('admin');
            this.canDisplayMLGenericCall = this.canDisplayAlertList;
        } else {
            this.canDisplayAlertList = false;
        }

        this.canDisplayMLGenericCall = this.canDisplayAlertList;

        const configurations = JSON.parse(localStorage.getItem('configurations')) || [];
        const orbis = configurations.find(config => config.type === 'front' && config.name === 'orbisCSV') || false;

        this.canDisplayOrbisButton = orbis && orbis.content === true
            ? orbis.content
            : false;
    }

    canDisplaySubscribeToCurrentModuleButton(): boolean {
        const currentSubscribableModule = this.currentSubscribableModule;
        if (currentSubscribableModule) {
            return !this._alertService.getSubscribedAlertByName(currentSubscribableModule.alertName);
        }
        return false;
    }

    canDisplayUnsubscribeFromCurrentModuleButton(): boolean {
        const currentSubscribableModule = this.currentSubscribableModule;
        if (currentSubscribableModule) {
            return !!this._alertService.getSubscribedAlertByName(currentSubscribableModule.alertName);
        }
        return false;
    }

    async logout(): Promise<void> {
        try {
            this._layoutService.startLoading();
            await this._authenticationService.logout();
            this._layoutService.stopLoading();
        } catch (e) {
            this._layoutService.stopLoading();
            console.error(e);
            throw e;
        }
    }

    getTitle(): string {
        const currentName = (this.$state.params['fromFilterSearchList'] && this.$state.params['fromFilterSearchList'] === 'true') ? 'stay-list-all' : this.$state?.current?.name;
        if (currentName) {
            return this._translationHelperService.getStateDisplayName(currentName);
        }
        return '';
    }

    goToEditPassword(): void {
        const currentUser = JSON.parse(localStorage.getItem('user')) || {};
        if (currentUser &&
            currentUser.id) {
            this.$state.go('user-edit-password', {id: currentUser.id});
        }
    }

    goToAlertList(): void {
        if (this.canDisplayAlertList) {
            this.$state.go('alert-list');
        }
    }

    updateFile() {
        if (this.canDisplayOrbisButton) {
            this._dataWebApiService.post([]).toPromise();
            this._snackBarService.success(this._translateService.instant('SUCCESS.UPDATE'));
            return;
        }
    }

    goToMLGenericCallPage(): void {
        if (this.canDisplayMLGenericCall) {
            this.$state.go('machine-learning-generic-call');
        }
    }

    async subscribeToAlert(unsubscribe?: boolean): Promise<void> {
        try {
            const currentSubscribableModule = this.currentSubscribableModule;
            if (currentSubscribableModule) {
                this._layoutService.startLoading();
                if (!unsubscribe) {
                    await this._alertService.subscribeToAlert(currentSubscribableModule.alertName);
                } else {
                    await this._alertService.unsubscribeFromAlert(null, currentSubscribableModule.alertName);
                }
                this._layoutService.stopLoading();
            }
        } catch (e) {
            this._layoutService.stopLoading();
            console.error(e);
            throw e;
        }
    }

    openVersionDisplayDialog(event: any): void {
        event?.stopPropagation();
        this._versionDisplayDialog.openDialog();
    }

    openDocumentationDialog(): void {
        const title = this._translateService.instant('STUFF.DOCUMENTATION');
        this._matDialog.open(DocumentTypeDocumentListDisplayDialogComponent, {
            data: {
                isDocumentation: true,
            },
            autoFocus: false,
            panelClass: 'document-type-document-list-display-dialog'
        });
    }

    submitIssue() {
        (window as any).ATL_JQ_PAGE_PROPS = {
            triggerFunction: function(showCollectorDialog, hideCollectorDialog) {
                (window as any).showCollectorDialog = showCollectorDialog;
            },
            fieldValues: {
                email: this._currentUser.email,
                customfield_10067: (window as any).location.href,
            }
        };

        this._loadCollectorScript();
    }

    private _loadCollectorScript() {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://collective-thinking.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/` +
        `-ia3qmx/b/4/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:` +
        `issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=fr-FR&collectorId=7a158f90`;

        script.onload = () => {
            this._initializeCollector();
        };

        this.renderer.appendChild(document.body, script);
    }

    private _initializeCollector() {
        setTimeout(() => {
            (window as any).showCollectorDialog();
        }, 200);
    }
}
