import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BroadcastService} from '../../../../../core/services/broadcast.service';
import {Subscription} from 'rxjs';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import * as _ from 'lodash-es';
import {TranslateService} from '@ngx-translate/core';
import {StayDetailDocumentListService} from './stay-detail-document-list.service';
import {IDocumentation} from '../../../../../shared/components/document-type-document-list-display/dialog/document-type-document-list-display-dialog.component';

@Component({
    selector: 'ct-stay-detail-document-list',
    templateUrl: './stay-detail-document-list.component.html',
    styleUrls: ['./stay-detail-document-list.component.scss']
})
export class StayDetailDocumentListComponent implements OnInit, OnDestroy {
    private _subscriptions: Subscription[] = [];

    stayDocuments: any = {};
    patientDocuments: any = {};
    canDisplayStayRisk: boolean;
    isActive: boolean;
    isStayFileNameContainerHover: boolean[][] = [];
    isPatientFileNameContainerHover: boolean[][][] = [];
    displayType: string;
    structuredDataLines: any[];

    // Document types from ML
    // undefined means doc inferredDocumentType property is null
    inferredDocumentTypes = ['crh', 'cro', 'anapath', 'other', 'undefined'];

    @Input() documents: any;
    @Input() documentations: IDocumentation[];
    @Input() forNearAutomationList = false;
    @Input() applyPaddingTop = false;
    @Input() maxHeight = '300px';

    @Output() clickOnDocumentName: EventEmitter<any> = new EventEmitter<any>();
    @Output() clickOnDocumentationName: EventEmitter<any> = new EventEmitter<any>();

    constructor(private _translateService: TranslateService,
                private _broadcastService: BroadcastService,
                private _stayDetailDocumentListService: StayDetailDocumentListService,
                private _configurationService: ConfigurationService) { }

    ngOnInit(): void {
        this._initConfig();
        if (this.documents) {
            this._onRetrieveDocuments(this.documents);
        }
        if (this.documentations) {
            this._onRetrieveDocumentations(this.documentations);
        }
        this._subscribeToBroadcast();
    }

    private _subscribeToBroadcast(): void {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'stayDetailDocument::openCloseDocument':
                        this._onOpenCloseOneDocument(res.data);
                        break;
                    case 'stayDetailDocument::openCloseAllDocuments':
                        this._onOpenCloseAllDocuments(res.data.type, res.data.status);
                        break;
                    case 'stayDetailDocument::detach':
                    case 'stayDetailDocument::attach':
                    case 'stayDetailDocument::updateDocumentsDone':
                        this._onRetrieveDocuments(res.data.documents);
                        break;
                    default:
                }
            });
        this._subscriptions.push(sub);
    }

    private _initConfig(): void {
        this.canDisplayStayRisk = this._configurationService.getConfigurationContent('front', 'health.canDisplayStayRisk');
        this.displayType = this._configurationService.getConfigurationContent('front', 'health.document.listingBoxDisplayType');
    }

    private _onOpenCloseOneDocument(data: any): void {
        if (data) {
            this._updateDocumentProperty(data, 'isOpened', data.isOpened);
        }
    }

    private _updateDocumentProperty(data: any, key: string, value: any): void {
        if (data) {
            const docsArray = !data.isPatientDocument ? this.stayDocuments : this.patientDocuments;
            const doc = docsArray[data.inferredDocumentType] && docsArray[data.inferredDocumentType][data.documentType] ?
                docsArray[data.inferredDocumentType][data.documentType].find(el => el.id === data.documentId) : null;
            if (doc) {
                doc[key] = value;
            }
        }
    }

    private _onOpenCloseAllDocuments(type: string, status: boolean): void {
        switch (type) {
            case 'stay':
                this._setIsOpenedProperty('stay', status);
                break;
            case 'patient':
                this._setIsOpenedProperty('patient', status);
                break;
            case 'all':
                this._setIsOpenedProperty('stay', status);
                this._setIsOpenedProperty('patient', status);
                break;
        }
    }

    private _onRetrieveDocuments(documents: any): void {
        if (documents) {
            // We load all the documents in stayDetailDocumentComponent
            this.stayDocuments = this._groupDocumentsByDocumentType(_.cloneDeep(documents.stayDocuments));
            this.patientDocuments = this._groupDocumentsByDocumentType(_.cloneDeep(documents.patientDocuments));
            this.isActive = true;
        }
    }

    private _onRetrieveDocumentations(documentations: IDocumentation[]): void {
      this.isActive = true;
    }

    private _setIsOpenedProperty(type: string, status: boolean): void {
        const docsAsObj = type === 'stay' ? this.stayDocuments : this.patientDocuments;
        this._stayDetailDocumentListService.setIsOpenedProperty(docsAsObj, status);
    }

    private _groupDocumentsByDocumentType(documentsAsObj: { string: any }): any {
        const newDocuments = {};
        if (documentsAsObj) {
            Object.entries(documentsAsObj).forEach(([inferredDocumentTypeName, documentsAsArray])  => {
                if (documentsAsArray) {
                    documentsAsArray.forEach(doc => {
                        if (!doc.documentType.name) {
                            doc.documentType.name = doc.documentType.slug || '';
                        }
                    });
                    newDocuments[inferredDocumentTypeName] = _.groupBy(documentsAsArray, 'documentType.name');
                }
            });
        }
        return newDocuments;
    }

    getObjectKeys(object: any): string[] {
        return object ? Object.keys(object) : [];
    }

    getInferredDocumentTypeDisplayText(inferredDocumentTypeName: string): string {
        switch (inferredDocumentTypeName) {
            case 'crh':
                return this._translateService.instant('DOCUMENT_TYPE.CRH');
            case 'cro':
                return this._translateService.instant('DOCUMENT_TYPE.CRO');
            case 'anapath':
                return this._translateService.instant('DOCUMENT_TYPE.ANAPATH');
            default:
                return '';
        }
    }

    getFilename(documents: any[], index: number): string {
        const hasSubDocument = documents.some((document: any) => !document.subDocumentId);
        if (hasSubDocument) {
            const filenames = documents.map((document: any) => document.name);
            return filenames.join(' , ');
        }

        return documents[index].name;
    }

    /**
     * Test if there is more documents in next inferred types
     * to decide to whether display or not a divider
     * @param useCase
     * @param index
     */
    hasMoreDocuments(useCase: string, index: number): boolean {
        let hasMoreDocuments = false;
        if (useCase) {
            if (this.inferredDocumentTypes[index + 1]) {
                const docsObject = useCase === 'stay' ? this.stayDocuments : this.patientDocuments;
                for (let i = index; i < this.inferredDocumentTypes.length; i++) {
                    if (docsObject[this.inferredDocumentTypes[i + 1]]) {
                        hasMoreDocuments = true;
                        break;
                    }
                }
            }
        }
        return hasMoreDocuments;
    }

    hasDocuments(useCase: string): boolean {
        const keys = useCase === 'stay' ? Object.keys(this.stayDocuments) : Object.keys(this.patientDocuments);
        return !!keys?.length;
    }

    /**
     * To add a class on file name container to hover only span (to not change open icon color on hover)
     * @param i
     * @param j
     * @param k
     * @param isHover
     * @param type for stay or patient docs
     */
    onMouseOverChange(i: number, j: number, k: number, isHover: boolean, type: string): void {
        const array = type === 'stay' ? this.isStayFileNameContainerHover : this.isPatientFileNameContainerHover;
        if (typeof array[i] === 'undefined') {
            array[i] = [];
        }
        if (typeof array[i][j] === 'undefined') {
            array[i][j] = [];
        }
        array[i][j][k] = isHover;
    }

    onClickOnDocumentName(document: any): void {
        if (document) {
            this.clickOnDocumentName.emit(document);
            this._broadcastService.send('stayDetailDocumentList::clickOnDocument', {documentId: document.id});
            this._setIsOpenedProperty('stay', false);
            this._setIsOpenedProperty('patient', false);
            document.isOpened = true;
        }
    }
    onClickOnDocumentatinoName(documentation: IDocumentation) {
        this.clickOnDocumentationName.emit(documentation);
    }

    ngOnDestroy(): void {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}
