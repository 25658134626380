import {Injectable} from '@angular/core';
import {HttpClient, HttpParameterCodec, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';
import {Moment} from 'moment';
import {AppLogService} from '../app-log/app-log.service';
import {DataHelperService} from '../services/data.helper.service';

class CustomEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    private _BASE_URL = `${environment.apiUrl}/api/v2`;

    constructor(
        public http: HttpClient,
        private _logService: AppLogService,
    ) {
    }
    /**
     * Merges default HTTP request options with custom options.
     *
     * @param {any} customOptions - Custom options to merge into the default options.
     * @param {boolean} [deleteEmptyNullParams=true] - Whether to remove empty, null, or undefined parameters from custom options.
     *
     * @returns {any} The merged HTTP request options.
     */
    private static _setRequestOptions(customOptions: any, deleteEmptyNullParams: boolean = true): any {
        const options: any = {
            withCredentials: true
        };

        if (!customOptions) {
            return options;
        }

        return HttpService._handleCustomOptions(customOptions, deleteEmptyNullParams, options);
    }

    /**
     * Merges custom HTTP request options with default options.
     *
     * If custom parameters exist, this method optionally removes empty, null, or undefined values,
     * then constructs new HTTP parameters using a custom encoder and merges additional options such as
     * `responseType` and `observe` with the defaults.
     *
     * @param {any} customOptions - The custom options including parameters and additional configurations.
     * @param {boolean} deleteEmptyNullParams - Flag indicating whether to delete empty, null, or undefined parameters.
     * @param {any} options - The default options to merge with.
     *
     * @returns {any} The merged HTTP request options.
     */
    private static _handleCustomOptions(customOptions: any, deleteEmptyNullParams: boolean, options: any): any {
        if (customOptions.params) {
            if (deleteEmptyNullParams) {
                DataHelperService.deleteEmptyUndefinedNullFromObject(customOptions.params);
            }

            options = {
                ...options,
                params: new HttpParams({
                    fromObject: {...customOptions.params},
                    encoder: new CustomEncoder()
                })
            };
        }

        if (customOptions.responseType) {
            options = {
                ...options,
                responseType: customOptions.responseType
            };
        }

        if (customOptions.observe) {
            options = {
                ...options,
                observe: customOptions.observe
            };
        }

        return options;
    }

    /**
     * Return true if the given url is within the Research part of the application.
     *
     * @param currentUrl
     */
    isResearchUrl(currentUrl: string): boolean {
        const searchUrls = [
            'filter-search-patient',
            'patient',
        ];

        let isSearchUrl = false;

        for (const url of searchUrls) {
            isSearchUrl = currentUrl.includes(url);

            if (isSearchUrl) {
                break;
            }
        }

        return isSearchUrl;
    }

    /**
     * Send log error to ES
     * @param error
     * @param startMoment
     * @param url
     * @param method
     * @private
     */
    private _sendLogErrorToLogStash(error: any, startMoment: Moment, url: string, method: string) {
        const requestDuration = moment().diff(startMoment, 'milliseconds');
        this._logService.logError(`${method} error code ${error.status}`, requestDuration, url);
    }

    private _request(method: string, url: string, body?: any, options?: any) {
        return new Observable((observer: any) => {
            const requestBeginTime = moment();
            options = options || {};
            if (body) {
                options = {
                    ...options,
                    body
                };
            }
            // If goes well then return it
            // otherwise send log error to logStash
            this.http.request(method, url, options)
                .subscribe(response => {
                        observer.next(response);
                        observer.complete();
                    },
                    error => {
                        this._sendLogErrorToLogStash(error, requestBeginTime, url, method);
                        observer.error(error);
                    }
                );
        });
    }

    getFile(url: string, options?: any): Observable<any> {
        options = HttpService._setRequestOptions(options);
        return this._request('GET', `${environment.apiUrl}/${url}`, null, options);
    }

    get(url: string, options?: any, deleteEmptyNullParams: boolean = true): Observable<any> {
        options = HttpService._setRequestOptions(options, deleteEmptyNullParams);
        return this._request('GET', `${this._BASE_URL}/${url}`, null, options);
    }

    post(url: string, body: any, options?: any): Observable<any> {
        options = HttpService._setRequestOptions(options);
        return this._request('POST', `${this._BASE_URL}/${url}`, body, options);
    }

    patch(url: string, body: any, options?: any): Observable<any> {
        options = HttpService._setRequestOptions(options);
        return this._request('PATCH', `${this._BASE_URL}/${url}`, body, options);
    }

    delete(url: string, body?: any, options?: any): Observable<any> {
        options = HttpService._setRequestOptions(options);
        return this._request('DELETE', `${this._BASE_URL}/${url}`, body, options);
    }
}
