import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionDiagnosticMissingComponent} from './search-engine-condition-diagnostic-missing.component';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {StateService} from '@uirouter/core';
import {TranslationHelperService} from '../../../../core/services/translation.helper.service';
import {DiagnosticSlugHelperService} from '../diagnostic-slug/diagnostic-slug-helper.service';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionDiagnosticMissingService extends SearchEngineCondition {
    private _codificationLabels: string[];

    validateWithCheckbox: boolean;
    separator: string;
    isMCOUseCase: boolean;
    isSSRUseCase: boolean;

    constructor(public $state: StateService,
                private _translateService: TranslateService,
                private _translationHelperService: TranslationHelperService,
                private _configurationService: ConfigurationService,
                private _diagnosticSlugHelper: DiagnosticSlugHelperService,
    ) {
        super(['diagnosticMissingSlug', 'diagnosticMissingCodificationLabel', 'diagnosticAutoPricing'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.EXCLUDING_DIAGNOSTICS'),
            'diagnosisMissing',
            SearchEngineConditionDiagnosticMissingComponent,
            true);
        this.init();
    }
    init(uriParams?: any, apiParams?: ApiCriteria, validateWithCheckbox?: boolean): void {
        this._codificationLabels = this._diagnosticSlugHelper.getDefaultCodificationLabels(this.isMCOUseCase, this.isSSRUseCase, this._translationHelperService.isFeatureAvailable('DR'))?.split(';');
        const diagnosticAutoPricing = this._configurationService.getConfigurationContent('front', 'filterSearch.revaluationDefaultValue') || true;
        this._initParams(diagnosticAutoPricing, validateWithCheckbox);
        this._initUriParams(diagnosticAutoPricing);
        // When we change the searchCondition we call init()
        // with validateWithCheckbox param
        if (typeof validateWithCheckbox !== 'undefined' &&
            validateWithCheckbox !== null) {
            this.validateWithCheckbox = validateWithCheckbox;
        } else {
            this.validateWithCheckbox = true;
        }
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    private _initParams(diagnosticAutoPricing: boolean, validateWithCheckbox: boolean): void {
        this.params = {
            diagnosticMissingSlug: [],
            diagnosticMissingCodificationLabel: [],
            diagnosticAutoPricing: diagnosticAutoPricing,
            diagnosticOperator: 'OR'
        };
        if (!this.$state.current.name.includes('patient')) {
            if (validateWithCheckbox === false &&
                typeof this.$state.params.diagnosticAutoPricing !== 'undefined' &&
                this.$state.params.diagnosticAutoPricing !== null) {
                this.params.diagnosticAutoPricing = this.$state.params.diagnosticAutoPricing;
            }
            if (this.$state.params.diagnosticAutoPricing === 'true' ||
                (typeof this.$state.params.diagnosticAutoPricing === 'undefined' &&
                    this.params.diagnosticAutoPricing)) {
                this.params.diagnosticMissingCodificationLabel = this._codificationLabels;
            }
        } else {
            this.params.diagnosticMissingCodificationLabel = this._codificationLabels;
        }
        this.updateDiagnosticMissingSlugParam(null);

    }

    /**
     * If we reverse a filterSearch with init diagnosticMissingSlug with diagnosticSlug
     */
    updateDiagnosticMissingSlugParam(newDiagnosticMissingSlug: string[]): void {
        newDiagnosticMissingSlug = newDiagnosticMissingSlug ?? JSON.parse(sessionStorage.getItem('diagnosticSlug'));
        if (newDiagnosticMissingSlug) {
            this.params.diagnosticMissingSlug = newDiagnosticMissingSlug;
        }
    }

    private _initUriParams(diagnosticAutoPricing: boolean): void {
        this.uriParams = {
            diagnosticMissingSlug: '',
            diagnosticMissingCodificationLabel: '',
            diagnosticAutoPricing: diagnosticAutoPricing.toString(),
            diagnosticOperator: 'OR'
        };
        if (!this.$state.current.name.includes('patient') &&
            (this.$state.params.diagnosticAutoPricing === 'true' ||
                (typeof this.$state.params.diagnosticAutoPricing === 'undefined' &&
                    this.uriParams.diagnosticAutoPricing))) {
            this.uriParams.diagnosticMissingCodificationLabel = this._codificationLabels;
        }
    }

    convertToUriParams(): void {
        this.uriParams.diagnosticMissingSlug = this.params.diagnosticMissingSlug.join(this.separator);
        this.uriParams.diagnosticMissingCodificationLabel = this.params.diagnosticMissingCodificationLabel?.length ? this.params.diagnosticMissingCodificationLabel?.join(this.separator) : this._codificationLabels?.join(this.separator);
        this.uriParams.diagnosticAutoPricing = this.params.diagnosticAutoPricing.toString();
        if (this.validateWithCheckbox === false &&
            typeof this.$state.params.diagnosticAutoPricing !== 'undefined' &&
            this.$state.params.diagnosticAutoPricing !== null) {
            this.uriParams.diagnosticAutoPricing = this.$state.params.diagnosticAutoPricing;
        }
    }

    convertToParams(): void {
        if (typeof this.uriParams.diagnosticMissingSlug === 'string' &&
            this.uriParams.diagnosticMissingSlug !== '') {
            this.params.diagnosticAutoPricing = this.uriParams.diagnosticAutoPricing === 'true';
            this.params.diagnosticMissingSlug = this.uriParams.diagnosticMissingSlug.split(this.separator);

            this.params.diagnosticMissingCodificationLabel = Array.isArray(this.uriParams.diagnosticMissingCodificationLabel)
                ? this.uriParams.diagnosticMissingCodificationLabel
                : this.uriParams.diagnosticMissingCodificationLabel?.split(this.separator) || [];

            if (this.validateWithCheckbox === false &&
                typeof this.$state.params.diagnosticAutoPricing !== 'undefined' &&
                this.$state.params.diagnosticAutoPricing !== null) {
                this.params.diagnosticAutoPricing = this.$state.params.diagnosticAutoPricing === 'true';
            }
        } else {
            this.params.diagnosticMissingSlug = [];
        }
    }

    convertToParamsFromApiParams(): void {
        this.params.diagnosticMissingSlug = this.apiParams.args.slugs;
        this.params.diagnosticMissingCodificationLabel = this.apiParams.args.codificationLabel;
        this.params.diagnosticAutoPricing = this.apiParams.args.revaluation;
        this.params.diagnosticOperator = this.apiParams.args.operator;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        // If this.params.diagnosticMissingCodificationLabel is empty then we want all codificationLabels
        const codificationLabels: string[] = this.params.diagnosticMissingCodificationLabel.length ? [...this.params.diagnosticMissingCodificationLabel] : this._codificationLabels;
        this.apiParams.args = {
            slugs: [...this.params.diagnosticMissingSlug],
            codificationLabel: codificationLabels,
            revaluation: this.params.diagnosticAutoPricing,
            operator: this.params.diagnosticOperator
        };
    }

    isValid(fields?: any): boolean {
        return ((!this.isUndefined(fields['diagnosticMissingSlug']) &&
                !this.isEmpty(fields['diagnosticMissingSlug'])) ||
            (this.validateWithCheckbox &&
                (!this.isUndefined(fields['diagnosticMissingSlug']) &&
                    !this.isEmpty(fields['diagnosticMissingSlug']))));
    }

    isValidApiParams(args: any): boolean {
        return !!(args?.slugs?.length && args?.codificationLabel?.length && args?.operator);
    }
}
