import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from '../../../modules/layout/layout.service';
import {MenuItemGroup, MenuService} from './menu.service';
import * as _ from 'lodash-es';
import {MenuGroupSubMenuComponent} from './group-sub-menu/menu-group-sub-menu.component';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {BroadcastService} from '../../services/broadcast.service';
import {ConfigurationService} from '../../../modules/configuration/configuration.service';

@Component({
    selector: 'ct-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
    private _destroy$: Subject<void> = new Subject<void>();

    menuItemGroups: MenuItemGroup[];
    subMenuComponent: any;
    isExpanded: boolean;
    isLoading = false;
    logoUrl = '';

    constructor(
        private _cdRef: ChangeDetectorRef,
        private _layoutService: LayoutService,
        private _broadcastService: BroadcastService,
        private _menuService: MenuService,
        private _config: ConfigurationService,
    ) {
    }

    ngOnInit(): void {
        this.subMenuComponent = MenuGroupSubMenuComponent;
        this._initIsExpanded();
        this._listenForLoadingBarStatus();
        this._loadAllMenuItemGroups();
        this._subscribeToBroadcast();

        this.logoUrl = this._config.getConfigurationContent('front', 'menu.logoUrl');
    }

    onSideNavScroll(event: any): void {
        if (event?.target) {
            this._broadcastService.send('menu::scroll', {scrollTop: event.target.scrollTop});
        }
    }

    private _subscribeToBroadcast(): void {
        this._broadcastService.broadcastData
            .pipe(takeUntil(this._destroy$))
            .subscribe(res => {
                switch (res.message) {
                    case 'subMenu::itemClicked':
                        this._setMenuItemGroups();
                        break;
                    case 'menu::updateMenuItemBadgeValue':
                        this._menuService.updateMenuItemBadgeValue(res.data?.menuItemGroupId, res.data?.state, res.data?.newBadgeValue);
                        this._setMenuItemGroups();
                        break;
                }
            });
    }

    private _initIsExpanded(): void {
        const localStorageItem: string = localStorage.getItem('isMenuExpanded');
        this.isExpanded = localStorageItem ? localStorageItem === 'true' : true;
    }

    private _listenForLoadingBarStatus(): void {
        this._layoutService.isLoading$
            .pipe(takeUntil(this._destroy$))
            .subscribe((isLoading: boolean) => {
                this.isLoading = isLoading;
                this._cdRef.detectChanges();
            });
    }

    private _setMenuItemGroups(): void {
        this.menuItemGroups = this._menuService.menuItemGroups || [];
    }

    private async _loadAllMenuItemGroups(): Promise<void> {
        try {
            await this._menuService.loadAllMenuItems();
            this._setMenuItemGroups();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    orderMenuItemGroups(): MenuItemGroup[] {
        return _.orderBy(this.menuItemGroups, ['rank'], ['asc']);
    }

    getSubMenuComponentConfig(itemGroup: MenuItemGroup): any {
        if (itemGroup) {
            return {
                itemGroupName: itemGroup.name,
                items: itemGroup.items,
                isMainMenuCollapsed: !this.isExpanded
            };
        }
        return {};
    }

    collapse(): void {
        this.isExpanded = !this.isExpanded;
        localStorage.setItem('isMenuExpanded', this.isExpanded.toString());
    }

    hasActiveItem(itemGroup: MenuItemGroup): boolean {
        return !!itemGroup?.items?.some(item => item.isActive);
    }

    canDisplayItemGroupBadge(itemGroup: MenuItemGroup): boolean {
        return !!itemGroup?.items?.some(item => this._menuService.canDisplayItemBadge(item));
    }

    getItemGroupBadgeValue(itemGroup: MenuItemGroup): number {
        return itemGroup?.items?.reduce((accumulator, currentValue) => {
            if (currentValue.hasBadge) {
                return accumulator + currentValue.badgeValue;
            }
            return accumulator;
        }, 0) || 0;
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
