import {Component, Input, OnInit} from '@angular/core';
import {ALL_DIAG_SOURCES, DIAG_MISSING, DIAG_PRESENT, DIAG_SOURCE_AUTO, DIAG_SOURCE_IMPORTED, DIAG_SOURCE_MANUAL} from './search-engine-condition-codification-type.service';

@Component({
    selector: 'ct-search-engine-codification-type',
    templateUrl: './search-engine-condition-codification-type.component.html',
    styleUrls: ['./search-engine-condition-codification-type.component.scss']
})
export class SearchEngineConditionCodificationTypeComponent implements OnInit {
    @Input() condition: any;

    status: string;
    sources = [
        {
            value: DIAG_SOURCE_IMPORTED,
            text: 'TOOLTIP.STATUS_CODED_IMPORTED'
        },
        {
            value: DIAG_SOURCE_MANUAL,
            text: 'TOOLTIP.STATUS_CODED_MANUAL'
        },
        {
            value: DIAG_SOURCE_AUTO,
            text: 'TOOLTIP.STATUS_CODED_AUTOMATIC'
        }
    ];

    public readonly DIAG_PRESENT = DIAG_PRESENT;
    public readonly DIAG_MISSING = DIAG_MISSING;

    ngOnInit() {
        this._initStatus();
    }

    private _initStatus() {
        const aiCodedStatuses = this.condition.service.params.aiCodedStatus;
        const sources = aiCodedStatuses ? aiCodedStatuses[0]?.args?.source : [];

        if (sources?.length) {
            this.status = DIAG_PRESENT;
            this.condition.service.params.aiCodedStatus = sources;
        } else {
            this.status = DIAG_MISSING;
            this.condition.service.params.aiCodedStatus = [DIAG_MISSING];
        }
    }

    changeStatus() {
        if (this.status === DIAG_PRESENT) {
            this.condition.service.params.aiCodedStatus = [DIAG_MISSING];
        } else if (this.condition.service.params.aiCodedStatus.includes(DIAG_MISSING)) {
            this.condition.service.params.aiCodedStatus = ALL_DIAG_SOURCES;
        }
    }
}
