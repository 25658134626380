import {Component, Input, OnInit} from '@angular/core';
import {DataSetElement} from '../../../modules/stay/shared/data-set-element.model';
import {DataSetElementService} from '../../../modules/stay/shared/data-set-element.service';
import {AuthenticationService} from '../../../core/authentication/authentication.service';

@Component({
    selector: 'ct-ai-coded-indicator',
    templateUrl: './ai-coded-indicator.component.html',
    styleUrls: ['./ai-coded-indicator.component.scss']
})
export class AiCodedIndicatorComponent implements OnInit {
    @Input() dataSetElement: DataSetElement;
    @Input() aiCodedStatus: number;

    isCurrentUserAdmin: boolean;

    constructor(
        private _dataSetElementService: DataSetElementService,
        private _authenticationService: AuthenticationService
    ) {
    }

    ngOnInit() {
        this.isCurrentUserAdmin = this._authenticationService.hasRole('admin');
    }

    async removeAiCodedStatus(event: any) {
        try {
            if (event) {
                event.stopPropagation();
            }

            if (this.isCurrentUserAdmin && this.dataSetElement) {
                await this._dataSetElementService.removeDataSetElementAiCodedStatus(this.dataSetElement);

                this.aiCodedStatus = 0;
            }
        } catch (e) {
            console.error(e);

            throw e;
        }
    }
}
