import {Injectable} from '@angular/core';
import {DataSetElement, StatusName} from '../shared/data-set-element.model';
import * as moment from 'moment';
import * as _ from 'lodash-es';
import {PatientApiService} from '../../../core/api-services/patient/patient.api.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslationHelperService} from '../../../core/services/translation.helper.service';
import {DashboardService} from '../../dashboard/dashboard.service';
import {StateService} from '@uirouter/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {StayDetailPatientIppEditDialogComponent} from './shared/stay-detail-patient-ipp-edit-dialog/stay-detail-patient-ipp-edit-dialog.component';
import {BroadcastService} from '../../../core/services/broadcast.service';
import {first, map} from 'rxjs/operators';
import {ConfirmStatusDialogComponent} from '../../../shared/components/confirm-status-dialog/confirm-status-dialog.component';
import {DataSetElementService} from '../shared/data-set-element.service';
import {ConfigurationService} from '../../configuration/configuration.service';
import {DataHelperService} from '../../../core/services/data.helper.service';
import {SearchEngineAdvancedService} from '../../search-engine/advanced/search-engine-advanced.service';
import {CriteriaTypes} from '../../search-engine/advanced/query-builder/enums/types';
import {AuthenticationService} from '../../../core/authentication/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class StayDetailService {
    private _isQualityControlStay: boolean;
    private _CAN_DUPLICATE_ACT_CODIFICATION: boolean;
    private _CAN_DISPLAY_CONFIRM_DIALOG_FOR_PATIENT_MODULE: boolean;
    private  canAdminUpdateStatus: boolean;

    constructor(public $state: StateService,
                private _matDialog: MatDialog,
                private _patientApiService: PatientApiService,
                private _searchEngineAdvancedService: SearchEngineAdvancedService,
                private _translateService: TranslateService,
                private _configurationService: ConfigurationService,
                private _dataSetElementService: DataSetElementService,
                private _translationHelperService: TranslationHelperService,
                private _broadcastService: BroadcastService,
                private _dashboardService: DashboardService,
                private _authenticationService: AuthenticationService) {
        this._isQualityControlStay = false;
        this._CAN_DUPLICATE_ACT_CODIFICATION = this._configurationService.getConfigurationContent('front', 'SSR.stayCodification.canDuplicateActCodification');
        this._CAN_DISPLAY_CONFIRM_DIALOG_FOR_PATIENT_MODULE = this._configurationService.getConfigurationContent('front', 'qualityControl.canDisplayUpdateStayStatusConfirmationDialogForPatientModule');
        this.canAdminUpdateStatus = this._configurationService.getConfigurationContent('front', 'health.canAdminUpdateStatus');
    }

    getIsQualityControlStay(): boolean {
        return this._isQualityControlStay;
    }

    public getDiagnosisMissingSlug(filtersearchQuery): any {
        return this._searchEngineAdvancedService.filterSearchQueryGetCondition(
            CriteriaTypes.DiagnosisMissing, filtersearchQuery?.args?.criteria
        );
    }

    /**
     * Return whether the given filterSearch query contains a diagnosisMissing
     * criteria or not.
     *
     * @param filterSearchQuery
     */
    hasDiagnosisMissingSlug(filterSearchQuery): boolean {
        return this._searchEngineAdvancedService
            .filterSearchQueryHasCondition(
                CriteriaTypes.DiagnosisMissing, filterSearchQuery?.args?.criteria
            );
    }

    private _getActPresenceSlug(filtersearchQuery): any {
        return this._searchEngineAdvancedService.filterSearchQueryGetCondition(
            'actPresence', filtersearchQuery?.args?.criteria
        );
    }
    private hasActPresenceSlug(filtersearchQuery): any {
        return this._searchEngineAdvancedService.filterSearchQueryGetCondition(
            'actPresence', filtersearchQuery?.args?.criteria
        );
    }
    private _getActMissingSlug(filtersearchQuery): any {
        return (this._getActPresenceSlug(filtersearchQuery)?.args?.values?.find(el => el.operator === 'NAND') || this._getActPresenceSlug(filtersearchQuery)?.args?.values?.find(el => el.operator === 'NAND')) ? this._getActPresenceSlug(filtersearchQuery) ?? null : null;
    }
    private hasActMissingSlug(filtersearchQuery): any {
        return !!(this.hasActPresenceSlug(filtersearchQuery)?.args?.values?.find(el => el.operator === 'NAND') || this.hasActPresenceSlug(filtersearchQuery)?.args?.values?.find(el => el.operator === 'NOR')) ? true ?? false : false;
    }

    /**
     * Set this._isQualityControlStay to true if the stay has a GHM (CT or not) and if it's different from 90Z*
     * otherwise to false
     */
    setIsQualityControlStay(dataSetElement: DataSetElement, isSRRUseCase: boolean) {
        let value = false;
        if (dataSetElement?.dataSetContent) {
            let ghm;
            if (!isSRRUseCase) {
                ghm = dataSetElement.dataSetContent.healthGhm || (dataSetElement.dataSetContent.ctHealthGhsGhm && dataSetElement.dataSetContent.ctHealthGhsGhm.healthGhm);
            } else if (dataSetElement.dataSetContent.healthStayAdditional) {
                ghm = dataSetElement.dataSetContent.healthStayAdditional.healthGme || (dataSetElement.dataSetContent.healthStayAdditional.ctHealthGmtGme && dataSetElement.dataSetContent.healthStayAdditional.ctHealthGmtGme.healthGme);
            }
            value = !!(ghm && ghm.slug && ghm.slug.substr(0, 3) !== '90Z');
        }
        this._isQualityControlStay = value;
    }

    private _getGhmExtension(ghm: any) {
        let extension = null;
        if (ghm) {
            if (typeof ghm === 'object') {
                extension = ghm.ghm.substr(ghm.length - 1);
            } else {
                extension = ghm.substr(ghm.length - 1);
            }
        }
        return extension;
    }

    private _convertDrgToLevel(ghm: string) {
        let level: number;
        const extension = this._getGhmExtension(ghm);
        switch (extension) {
            case 'Z':
                level = 1;
                break;
            case 'B':
                level = 3;
                break;
            case 'A':
                level = 4;
                break;
            default:
                level = 2;
                break;
        }
        return level;
    }

    convertGhmToLevel(ghm: string) {
        if (this._translationHelperService.isCurrentLanguageDRG()) {
            return this._convertDrgToLevel(ghm);
        }
        let level: number;
        const extension = this._getGhmExtension(ghm);
        switch (extension) {
            case '1':
            case 'A':
                level = 1;
                break;
            case '2':
            case 'B':
                level = 2;
                break;
            case '3':
            case 'C':
                level = 3;
                break;
            case '4':
            case 'D':
                level = 4;
                break;
            default:
                level = 0;
                break;
        }
        return level;
    }

    getChipColor(dataSetElement: DataSetElement, creationDate: string) {
        if (dataSetElement?.dataSetContent?.endDate) {
            const momentCreationDate = moment(creationDate);
            const momentStayEndDate = moment(dataSetElement.dataSetContent.endDate);
            const diff = momentCreationDate.diff(momentStayEndDate, 'days');
            if (diff > 0) {
                if (diff > 180) {
                    return 'red';
                } else if (diff > 90) {
                    return 'orange';
                } else {
                    return 'green';
                }
            } else {
                if (diff < -180) {
                    return 'red';
                } else if (diff < -90) {
                    return 'orange';
                } else {
                    return 'green';
                }
            }
        } else {
            return 'grey';
        }
    }

    isStayMoreThan3NightsOrEras(dataSetElementParent: DataSetElement) {
        if (dataSetElementParent?.dataSetContent) {
            return dataSetElementParent.dataSetContent.nbDay >= 3 || dataSetElementParent.dataSetContent.eras;
        }
        return false;
    }

    /**
     * Returns DP Slug
     * @param dataSetElement
     */
    getMainDiagnostics(dataSetElement: DataSetElement) {
        if (dataSetElement?.dataSetElementDiagnostic) {
            return dataSetElement
                .dataSetElementDiagnostic
                .find(el => el.codificationLabel.id === 1);
        }
        return null;
    }
    /**
     * Returns true if a dataSetElement has filterSearch diagnosticMissingSlug as coded diagnostics
     * @param dataSetElement
     * @param filterSearch
     */
    hasCodedDiagnostics(dataSetElement: DataSetElement, filterSearch: any) {
        if (this.hasDiagnosisMissingSlug(filterSearch?.params?.query) &&
            dataSetElement?.dataSetElementDiagnostic) {
            const diagnosticSlugs = this.getDiagnosisMissingSlug(filterSearch?.params?.query)?.args?.slugs;
            const codedDiagnostic = dataSetElement
                .dataSetElementDiagnostic
                .find(el => diagnosticSlugs.includes(el.diagnostic.slug));
            return !!codedDiagnostic;
        }
        return false;
    }

    /**
     * Returns true if a dataSetElement has filterSearch actMissing as coded acts
     * @param dataSetElement
     * @param filterSearch
     */
    hasCodedActs(dataSetElement: DataSetElement, filterSearch: any) {
        if (this.hasActMissingSlug(filterSearch?.params?.query) &&
            dataSetElement?.dataSetContent?.linkActNotGrouped) {
            const actSlugs = this._getActMissingSlug(filterSearch?.params?.query)?.args?.values.find(el => el.operator === 'NAND' || el.operator === 'NOR');
            const codedAct = dataSetElement
                .dataSetContent.linkActNotGrouped
                .find(el => actSlugs.slugs.includes(el.slug));
            return !!codedAct;
        }
        return false;
    }

    /**
     * Returns the first filterSearch from array that matches findRevalDiagnostic()
     * If returns null then there is no CMA filterSearch which has revaluation
     * @param dataSetElement
     * @param filtersSearch
     */
    getRevalCMA(dataSetElement: DataSetElement, filtersSearch: any[]) {
        if (dataSetElement &&
            filtersSearch) {
            // We select this filterSearch if its diagnosticMissingSlug (or diagnosticMissingSlug) param has a diagnostic that has a revaluation
            const filterSearchToReturn = this.findRevalDiagnostic(dataSetElement, filtersSearch);
            if (!filterSearchToReturn) {
                // We return the first one only if we have non coded left
                return null;
            }
            return filterSearchToReturn;
        }
        return null;
    }

    /**
     * Find the first predictive diagnostic that is a DA, has a revaluation and matches a filterSearch diagnosticMissingSlug param
     * @param dataSetElement
     * @param filterSearch
     */
    findRevalDiagnostic(dataSetElement: DataSetElement, filterSearch: any) {
        if (dataSetElement && this.hasDiagnosisMissingSlug(filterSearch?.params?.query)) {
            const diagnosticSlugs = this.getDiagnosisMissingSlug(filterSearch?.params?.query)?.args?.slugs;
            return dataSetElement?.dataSetElementDiagnosticPredictive?.find(el => [3, 5].includes(el.codificationLabelId) &&
                    diagnosticSlugs.includes(el.diagnostic.slug) &&
                    ((el.pricing - dataSetElement.dataSetContent.pricing) > 0));
        }

        return null;
    }

    /**
     * Construct a proper array to display patient history
     * @private
     */
    formatDiagnosticHistory(patientStayHistory: any[]) {
        const allDiagnostics = {};
        if (patientStayHistory) {
            patientStayHistory.forEach(element => {
                if (element.contents) {
                    allDiagnostics[element.contents.id] = [];
                    if (element.children) {
                        element.children.forEach(child => {
                            if (child.contents &&
                                child.contents.dataSetElementDiagnostic) {
                                child.contents.dataSetElementDiagnostic.forEach(diagnostic => {
                                    if (allDiagnostics[element.contents.id]) {
                                        allDiagnostics[element.contents.id].push({
                                            ...diagnostic.diagnostic,
                                            codificationLabelId: diagnostic.codificationLabel ? diagnostic.codificationLabel.id : -1
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            });
            // Remove duplicates
            for (const key in allDiagnostics) {
                if (allDiagnostics.hasOwnProperty(key)) {
                    allDiagnostics[key] = allDiagnostics[key].filter((obj, pos, arr) =>
                        arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos);
                }
            }
        }
        return allDiagnostics;
    }

    async loadPatientStayHistory(patientId: number, dataSetContentId: number) {
        try {
            const params = {
                include: 'contents,children.contents.dataSetElementDiagnostic.codificationLabel,children.contents.dataSetElementDiagnostic.diagnostic,establishment'
            };
            return await this._patientApiService
                .getPatientStayHistory(patientId, dataSetContentId, params)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    goToPatientDetail(dataSetElement: DataSetElement) {
        const params = {
            ...JSON.parse(sessionStorage.getItem('lastPatientDetailParams')) || {},
            id: dataSetElement.dataSetContent.healthPatient.id,
            fromStayDetail: 'true'
        };
        const lastStayDetailParams: any = {...this.$state.params};
        sessionStorage.setItem('lastStayDetailParams', JSON.stringify(lastStayDetailParams));
        const functionToCall: () => void = () => this.$state.go('patient-detail', params);
        this.openUpdateStayStatusConfirmDialog(dataSetElement, functionToCall);
    }

    sendEventForDashboard(eventName: string, context: string, dataSetElement: DataSetElement) {
        let eventData: any = {
            stayId: dataSetElement.dataSetContent.stayId,
            endDate: dataSetElement.dataSetContent.endDate,
            type: dataSetElement.dataSetContent.type
        };
        if (eventName === 'time_spent') {
            eventData = {
                ...eventData,
                module: 'stay_detail',
                status: dataSetElement.codificationDataSetElementStatus.status,
                context
            };
        }
        this._dashboardService.sendEvent(eventName, eventData);
    }

    openPatientIPPEditDialog(patientId: number) {
        const dialogRef: MatDialogRef<StayDetailPatientIppEditDialogComponent> =
            this._matDialog.open(StayDetailPatientIppEditDialogComponent, {
                data: {
                    service: this,
                    patientId
                },
                autoFocus: false,
                panelClass: 'patient-ipp-edit-dialog'
            });

        dialogRef
            .afterClosed()
            .subscribe((IPP) => {
                if (IPP) {
                    this._broadcastService.send('stayDetail::editPatientIPP', {IPP});
                }
            });
    }

    async updatePatientIPP(patientId: number, newIPP: string) {
        try {
            const body = {
                data: {'ipp_original': newIPP}
            };
            return await this._patientApiService
                .updatePatientIPP(patientId, body)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

     canUpdateStatus(dataSetElement: DataSetElement, hasUpdatedCodification: boolean): boolean {
        const isAdmin = this._authenticationService.hasRole('admin');
        const canUserUpdateStatus = !isAdmin ||
            (isAdmin && this.canAdminUpdateStatus);

        if (!canUserUpdateStatus) {
            return false;
        }

        if (!hasUpdatedCodification &&
            ![StatusName.UNCHECKED, StatusName.WAITING].includes(this.getDatasetElementStatus(dataSetElement))) {
            return false;
        }

        return true;
    }

     _canDisplayConfirmDialogForPatientModule(): boolean {
        return this.$state.params.fromPatientDetail !== 'true' ||
            this._CAN_DISPLAY_CONFIRM_DIALOG_FOR_PATIENT_MODULE;
    }

     openUpdateStayStatusConfirmDialog(dataSetElement: DataSetElement, functionToCall: () => any) {
        const hasUpdatedCodification = sessionStorage.getItem('hasUpdatedCodification') === 'true';
         if (this._canDisplayConfirmDialogForPatientModule() &&
            this.canUpdateStatus(dataSetElement, hasUpdatedCodification)) {
            // dialog for set status when modification or modification on status
            const status = this.getStatusTarification(dataSetElement, hasUpdatedCodification);
            this._matDialog.open(ConfirmStatusDialogComponent, {
                data: {
                    title: this.getStayStatusConfirmDialogTitle(hasUpdatedCodification),
                    cancelButtonText: this._translateService.instant('STUFF.NO'),
                    validateButtonText: this._translateService.instant('STUFF.YES'),
                    validateButtonCustomMinWidth: '67px',
                    status: status
                },
                autoFocus: false
            })
                .afterClosed()
                .pipe(map(res => res), first())
                .subscribe(async res => {
                    if (res === 'true') {
                        // yes action on dialog
                        await this._dataSetElementService.changeDataSetElementStatus(dataSetElement, status, false, false);
                    } else if (res === 'waiting')  {
                            // waiting action
                            const waitStatus = this.getStatusTarification(dataSetElement, hasUpdatedCodification);
                             await this._dataSetElementService.changeDataSetElementStatus(dataSetElement, StatusName.WAITING, false, false, waitStatus);
                    }
                    // noting if no button is pressed
                    if (functionToCall) {
                        functionToCall();
                    }
                });
        } else if (functionToCall) {
            functionToCall();
        }
    }

    concatDataSetElementAndParentLinkActs(dataSetElement: DataSetElement) {
        if (dataSetElement?.dataSetContent?.linkAct &&
            dataSetElement?.parent?.dataSetContent?.linkAct) {
            return dataSetElement.dataSetContent.linkAct
                .concat(dataSetElement.parent.dataSetContent.linkAct);
        }
        return [];
    }

    getGroupedLinkActs(dataSetElement: DataSetElement) {
        const groupedLinkActs = {
            ccam: {classing: [], other: []},
            csarr: {classing: [], other: []},
            unk: {classing: [], other: []}
        };

        const isClassingActsActivated = this._translationHelperService.isFeatureAvailable('classingActs');
        const linkActs = this.concatDataSetElementAndParentLinkActs(dataSetElement);
        const linkActsByType = _.groupBy(linkActs, 'act.type');

        Object.keys(linkActsByType).forEach(key => {
            if (isClassingActsActivated) {
                groupedLinkActs[key].classing = linkActsByType[key].reduce((filteredLinkActs, currentLinkAct) => {
                    if (currentLinkAct.act.classing) {
                        currentLinkAct.act = {
                            ...currentLinkAct.act,
                            execution_date: currentLinkAct.executedAt
                        };
                        filteredLinkActs.push(currentLinkAct.act);
                    }
                    return filteredLinkActs;
                }, []);
                groupedLinkActs[key].other = linkActsByType[key].reduce((filteredLinkActs, currentLinkAct) => {
                    if (!currentLinkAct.act.classing) {
                        currentLinkAct.act = {
                            ...currentLinkAct.act,
                            execution_date: currentLinkAct.executedAt
                        };
                        filteredLinkActs.push(currentLinkAct.act);
                    }
                    return filteredLinkActs;
                }, []);
            } else {
                groupedLinkActs[key].other = linkActsByType[key].map(linkAct => {
                    return  {
                        ...linkAct.act,  // Assuming linkAct.act is the correct property
                        execution_date: linkAct.executedAt
                    };
                });
            }
        });
        return groupedLinkActs;
    }

    storeFirstSSRWeekCodification(dataSetElementParent: DataSetElement, isSSRUseCase: boolean) {
        // Only for SSR
        if (dataSetElementParent &&
            dataSetElementParent.children &&
            dataSetElementParent.children[0] &&
            isSSRUseCase) {
            const data: any = {
                diagnostics: dataSetElementParent.children[0].dataSetElementDiagnostic
            };
            if (this._CAN_DUPLICATE_ACT_CODIFICATION) {
                data.linkActs = dataSetElementParent.children[0].dataSetContent.linkAct;
            }
            sessionStorage.setItem('firstSSRWeekCodification', JSON.stringify(data));
        }
    }

    private _updateDataSetElementPricing(newGrouping: any, dataSetElement: DataSetElement, isSSRUseCase: boolean) {
        if (dataSetElement) {
            let nbOfPrimaryDiagnostics = 1;
            if (dataSetElement.dataSetElementDiagnostic) {
                const codificationLabelIds = !isSSRUseCase ? [1, 4] : [9];
                nbOfPrimaryDiagnostics = dataSetElement
                    .dataSetElementDiagnostic
                    .filter(el => codificationLabelIds.includes(el.codificationLabelId))
                    .length;
            }
            // If it's a DP removal we set pricing to N/A
            dataSetElement.dataSetContent.pricing = nbOfPrimaryDiagnostics > 0 ? newGrouping.pricing : null;
        }
    }

    async updateDataSetElementGrouping(dataSetElementId: number, dataSetElement: DataSetElement, isSSRUseCase: boolean) {
        try {
            const newGrouping: any =
                await this._dataSetElementService
                    .loadGrouping(dataSetElementId);
            const parentObj = !isSSRUseCase ? dataSetElement.dataSetContent : dataSetElement.dataSetContent.healthStayAdditional;
            const ctHealthKey = !isSSRUseCase ? 'ctHealthGhsGhm' : 'ctHealthGmtGme';
            const healthKey = !isSSRUseCase ? 'healthGhm' : 'healthGme';
            if (newGrouping &&
                Object.keys(newGrouping).length > 0) {
                if (DataHelperService.isDefinedAndNotNull(newGrouping.pricing)) {
                    this._updateDataSetElementPricing(newGrouping, dataSetElement, isSSRUseCase);
                }
                // To update dataSetElement.dataSetContent.ctHealthGhsGhm.healthGhm or
                // dataSetElement.dataSetContent.healthStayAdditional.ctHealthGmtGme.healthGme
                if (DataHelperService.isDefinedAndNotNull(newGrouping[healthKey])) {
                    if (DataHelperService.isDefinedAndNotNull(parentObj[ctHealthKey])) {
                        parentObj[ctHealthKey][healthKey] = newGrouping[healthKey];
                    } else {
                        parentObj[ctHealthKey] = {};
                        parentObj[ctHealthKey][healthKey] = newGrouping[healthKey];
                    }
                }
            } else {
                parentObj[ctHealthKey] = null;
                dataSetElement.dataSetContent.pricing = null;
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

     getStatusTarification(dataSetElement: DataSetElement, hasUpdateCodification?: boolean): StatusName {
        if (hasUpdateCodification === false) {
            return StatusName.VERIFIED;
        }
        const isRehabilitation = dataSetElement?.dataSetContent?.type === 'rehabilitation';
        return isRehabilitation ?
            this._getStatusTarificationRehabilitation(dataSetElement) : this._getStatusTarificationDefault(dataSetElement);
    }

    private _getStatusTarificationDefault(dataSetElement: DataSetElement): StatusName {
        const { healthGhm, ctHealthGhsGhm } = dataSetElement?.dataSetContent || {};
        if (!healthGhm) {
            return StatusName.PRIMO_CODAGE;
        }
        if (!!ctHealthGhsGhm && healthGhm.id !== ctHealthGhsGhm?.healthGhm?.id) {
            return StatusName.PRICING_IMPACT;
        }
        return StatusName.NO_PRICING_IMPACT;
    }

    private _getStatusTarificationRehabilitation(dataSetElement: DataSetElement): StatusName {
        const { healthStayAdditional } = dataSetElement?.dataSetContent || {};
        const { healthGme, ctHealthGmtGme } = healthStayAdditional || {};

        if (!healthGme) {
            return StatusName.PRIMO_CODAGE;
        }

        if (!!ctHealthGmtGme && healthGme.id !== ctHealthGmtGme?.healthGme?.id) {
            return StatusName.PRICING_IMPACT;
        }

        return StatusName.NO_PRICING_IMPACT;
    }

     getDatasetElementStatus(dataSetElement: DataSetElement, loopStatus?: boolean) {
        if (dataSetElement?.codificationDataSetElementStatus?.status) {
            if (loopStatus) {
                return dataSetElement.codificationDataSetElementStatus.loopStatus;
            }
            return dataSetElement.codificationDataSetElementStatus.status;
        }
        return StatusName.UNCHECKED;
    }

    private getStayStatusConfirmDialogTitle(hasUpdatedCodification: boolean) {
        if (hasUpdatedCodification) {
            return this._translateService.instant('QUALITY_CONTROL.CODIFICATION_UPDATE_MESSAGE');
        } else {
            return this._translateService.instant('QUALITY_CONTROL.NO_CODIFICATION_UPDATE_MESSAGE');
        }
    }
}
